.profile-card {
  width: 60%;
  margin: 5% auto;
}
.ant-card.profile-card.company-info-container.ant-card-bordered .ant-row {
  margin-top: 1em;
}

.profile-card-actions {
  display: flex;
  justify-content: flex-end;
  margin-right: 53px;
}

.profile-card-actions button {
  margin-left: 24px;
}

.profile-container {
  height: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 2em;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.profile-sidebar {
  min-width: 240px;
  padding: 0 20px;
}

.profile-details-container {
  margin-bottom: 24px;
}

.profile-details {
  display: flex;
  flex-direction: column;
}

.profile-details > h2 {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 1.5em;
  line-height: 1.1666666666666667;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.profile-details p {
  font-weight: 500;
  font-size: 16px;
}

.profile-menu {
  text-align: left;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid #efefef;
}

.profile-menu > ul {
  border-right: none;
  font-weight: 400;
  font-size: 16px;
}

.profile-submenu-item {
  padding-left: 48px !important;
}

.sub-section-title {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 1em;
}

.profile-main {
  text-align: left;
  min-width: 700px;
  max-width: 700px;
  overflow-y: scroll;
}

.profile-main-header span {
  font-size: 2em;
}

.profile-main-content {
  padding: 0 24px;
  font-size: 16px;
  font-weight: 400;
}

.about-you-container {
  display: flex;
  flex-wrap: wrap;
}

/* .about-you-container > div {
  width: 50%;
  text-align: left;
  line-height: 1.5;
} */

.about-you-container .card {
  width: 100%;
  padding: 10px 16px;
  margin: 16px 0;
  border-radius: 3px;
  border: 2px solid #efefef;
}

.about-you-container .card .body {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}

.about-you-container .card h3 {
  margin: 16px 0;
  padding-left: 5px;
}

.about-you-container .card .body div {
  width: 50%;
}

.about-you-container .card .body h4 {
  font-size: 14px;
  font-weight: 500;
  color: rgb(107, 119, 140);
}

.about-you-container .card .body p {
  font-size: 16px;
  color: #000000;
}

@media (min-width: 1600px) {
  .profile-card {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .profile-container {
    height: inherit;
    flex-direction: column;
    justify-content: flex-start;
  }

  .profile-main {
    min-width: inherit;
    max-width: inherit;
  }
}

@media (max-width: 576px) {
  .profile-card {
    width: 80%;
  }

  .profile-card > .ant-card-body > .actions {
    flex-direction: column;
    height: 100px;
  }

  .about-you-container .card .body div {
    width: 100%;
  }
}
