.header {
  width: 100%;
  max-height: 40px;
  background-color: #ed3835;
  line-height: 40px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  overflow-x: visible;
}

a.workhero-logo-container {
  align-self: flex-start;
}

/* button#menu-on-header {
  background-color: #ed3835;
  border: none;
  color: #fff;
  float: right;
  font-size: 16px;
  height: 40px;
  padding: 0;
} */

.workhero-logo {
  height: 35px;
  width: 175px;
  min-height: 35px;
  min-width: 175px;
  position: relative;
  top: -1px;
}

div.company-header-container {
  position: relative;
}

h3#company-on-header {
  align-self: center;
  display: inline-block;
  font-size: 22px;
  margin-bottom: 0;
  padding: 0 10px;
}

h3#company-on-header a {
  color: #fff;
}

div.company-header-container:hover .company-header-tooltip {
  transition-delay: 0.3s;
  visibility: visible;
}

span.company-header-tooltip {
  position: absolute;
  visibility: hidden;
  z-index: 10;
  width: 105px;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  text-align: center;
  border-radius: 4px;
  line-height: normal;
  padding: 6px 0;
  top: 50px;
  left: 70px;
}

.company-header-container span.company-header-tooltip::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 4px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
}

.header-secondary-actions {
  align-self: flex-end;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 150px;
  height: 100%;
}
.ant-popover.popover-card-help {
  z-index: 10;
}
.header-secondary-actions > #help-btn {
  color: #ffffff;
  background-color: #ed3835;
  padding-top: 5px;
  border: none;
  /* font-size: 25px; */
}

.header-secondary-actions > #help-btn > i {
  font-size: 25px;
}

.header-secondary-actions > #profile-dropdown-btn {
  background-color: #ed3835;
  border: none;
  color: #fff;
  font-size: 16px;
  height: 40px;
  padding: 0;
}

.pop-menu-header p a {
  color: inherit;
}

.account-options-popup p {
  cursor: pointer;
}

#help-menu .list {
  list-style: none;
  padding: 0;
  font-size: 14px;
}

#help-menu > .list > .item {
  padding: 5px;
  cursor: pointer;
}

#help-menu > .list > .item a {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}

#help-menu > .list > .item:hover,
#help-menu > .list > .item a:hover {
  color: #1890ff;
}

@media (max-width: 992px) {
  .header {
    justify-content: center;
  }
  .hide-md-down {
    display: none !important;
  }

  .header > .mobile-button {
    display: block;
  }

  .workhero-logo-container {
    margin: auto;
  }

  h3#company-on-header {
    display: none;
  }
}

@media (max-width: 480px) {
  .header-secondary-actions {
    width: 70px;
  }
}
