.login-form {
  width: 300px;
  margin: 5% auto auto auto;
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
  background-color: #ed3835;
  border: none;
  font-weight: bold;
}
.login-form-button:hover {
  background-color: #bd2a28;
  border: none;
}
.logo-login {
  width: 100%;
  margin-bottom: 5%;
}
.groups-card {
  width: 40%;
  margin: 5% auto;
}

.groups-modal {
  text-align: center;
}

.groups-card .ant-radio-group,
.groups-modal .ant-radio-group {
  text-align: left;
}
.groups-modal label.ant-radio-wrapper,
.groups-card label.ant-radio-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
}
a#forgotPass {
  position: relative;
  left: -95px;
  bottom: 5px;
}
.password-form {
  background-color: #fdfdfd;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  width: 350px;
  margin: 10% auto auto auto;
}
div#login-page {
  height: 100%;
  /* padding: 2em; */
}
div#login-columns-row {
  height: 100%;
}
div#login-form-container {
  width: 40%;
}
div#login-slider {
  width: 60%;
}

div#login-form-container > div {
  width: 300px;
}
.login-banner {
  top: 0;
  background-color: #ed3835;
  left: 0;
}

.banner-buffer {
  display: block;
  height: 1px;
  margin-bottom: 80px;
}

.login-banner h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: inherit;
  font-size: inherit;
  text-decoration: inherit;
  font-style: inherit;
  font-weight: inherit;
}

div#login-form-container div.alt-center a {
  color: #626365;
  font-size: 16px;
  margin: 0 2px;
}
div#login-form-container div.alt-center a:hover {
  text-decoration: underline;
}
/* #login-slider .slick-list , #login-slider .slick-track, #login-slider .slick-slide,
#login-slider .slick-slide div, #login-slider .slick-slide div > div{
  max-height: 400px;
} */
#login-slider .slick-slide div {
  max-height: 1000px;
}
#login-slider .slick-slide div img {
  max-height: 500px;
  margin: auto;
  width: 100%;
  height: auto;
  object-fit: contain;
}
#login-slider h2.login-slider-title {
  margin-top: 20px;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}

/**
* universal-dashboard customize login
*/
form#login-form-customize .ant-form-item-control-wrapper {
  display: inline-block;
}
form#login-form-customize .ant-form-item-control input[type='text'] {
  min-width: 400px;
}

.login-titles-section {
  margin: 10px;
  margin-top: 20px;
}

.dynamic-title {
  margin-top: 20px;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
}

.login-slider-inputs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.login-dynamic-titles-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 40px;
  margin-top: 40px;
}

.login-slider-dynamic-field {
  display: flex;
  flex-direction: row;
}

.login-slider-dynamic-field > img {
  max-height: 60px;
  max-width: 60px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.login-slider-dynamic-field input {
  width: 500px;
}

.login-slider-speed {
  margin: 9px;
}

.slider-images-section {
  margin: 10px;
  margin-bottom: 40px;
  padding: 20px;
  border: 2px dashed rgba(0, 0, 0, 0.2);
}

.slider-upload-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.slider-upload-container > .ant-upload-picture-card-wrapper {
  display: block;
  max-width: 500px;
}

.draggable-slider-url {
  max-width: 180px;
  max-height: 150px;
  min-height: 150px;
  min-width: 180px;
  margin: 2.5px;
  margin-top: 30px;
  position: relative;
}

.slider-url-card:hover {
  box-shadow: -1px 1px 10px 3px #e4e4e4;
}

.draggable-slider-url:hover .performance-card-close-icon {
  display: block;
}

.slider-url-card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -20px 0 30px 0;
}

.slider-url-card {
  min-width: 180px;
  min-height: 150px;
}

.slider-url-card:hover {
  cursor: grab;
}

.slider-url-card:active {
  cursor: grabbing;
}

.slider-url-card > .ant-card-body {
  height: 150px;
  width: 180px;
  padding: 0px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-url-img {
  display: block;
  max-height: 150px;
  max-width: 160px;
}

.customize-login-submit {
  display: flex;
  justify-content: center;
  margin: 60px;
}

.customize-login-submit > button {
  height: 60px;
  width: 300px;
  font-size: 20px;
}

.color-selector {
  display: 'flex';
  flex-direction: 'column';
}

.color-selector-rgba {
  display: 'flex';
  flex-direction: 'row';
  margin-top: 10px;
  font-weight: bold;
}

.color-selector-rgba .rgba-label {
  font-weight: normal;
  margin-left: 10px;
  margin-right: 5px;
}

.color-selector-rgba a {
  text-decoration: underline;
  font-weight: normal;
}

@media screen and (max-width: 1830px) {
  .login-slider-dynamic-field input {
    width: 400px;
  }
}
@media screen and (max-width: 1600px) {
  .login-slider-inputs {
    flex-direction: column;
  }
  .login-slider-dynamic-field input {
    width: 400px;
  }
}
@media screen and (max-width: 1024px) {
  div#login-columns-row {
    flex-direction: column;
  }
  div#login-form-container {
    order: 1;
    margin-bottom: 5%;
    width: 100%;
  }
  div#login-slider {
    order: 2;
    width: 100%;
  }

  #login-slider .slick-slide div {
    max-height: 300px;
  }
  #login-slider .slick-slide div img {
    max-height: 300px;
    margin: auto;
  }
  div#login-page {
    height: auto;
    background-color: #f0f2f5;
  }
  .login-slider-dynamic-field input {
    width: 250px;
    min-width: 250px !important;
  }
}
