.associates-table-title {
  display: flex;
  justify-content: center;
  align-content: center;
}

.associates-table-title h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.associates-table-selector-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  height: min-content;
  padding-bottom: 15px;
}

#associates-table
  .ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 12px !important ;
}

.summary-table-container {
  border-top: 1px solid black;
  position: sticky;
  bottom: 0;
}

.associate-table-range-view-selector {
  display: inline-flex;
}

.associates-table-group-date-selector .ant-calendar-picker {
  width: 195px;
}

.associates-table-group-date-selector {
  display: flex;
  align-items: center;
}

#associates-table thead > tr > th {
  background-color: black;
  color: whitesmoke;
}

.get-overview-container,
.download-csv-container {
  margin-left: 15px;
}

#associates-table
  .ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-sorters
  .ant-table-filter-icon:hover {
  background-color: black;
  color: #1890ff;
}

#associates-table
  .ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-sorters
  .ant-table-filter-icon {
  height: 1.2rem;
  width: 1rem;
  background-color: black;
}

#associates-table span.ant-table-column-title {
  font-size: 0.7rem;
  position: relative;
}

#associates-table span.ant-table-column-sorter {
  position: absolute;
  margin-left: 0;
  bottom: 8px;
  right: 0px;
}

#associates-table .ant-table-thead > tr > th .ant-table-filter-icon > svg {
  position: absolute;
  top: 45%;
  left: 50%;
}

#associates-table
  i.anticon.anticon-edit.ant-table-filter-icon.ant-dropdown-trigger {
  background-color: black;
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: rgb(237, 237, 237);
}

#associates-table
  i.anticon.anticon-search.ant-table-filter-icon.ant-table-filter-open.ant-dropdown-trigger.ant-dropdown-open {
  background-color: black;
  color: #1890ff;
}

.summary-table-container .ant-table-tbody > tr > td {
  padding: 3px;
  font-weight: bold;
}

.associates-table-container .ant-table-tbody > tr > td {
  padding: 3px;
}

.associates-table-container
  th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-align-center.ant-table-row-cell-break-word {
  padding: 8px;
}
