@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap);
.header {
  width: 100%;
  max-height: 40px;
  background-color: #ed3835;
  line-height: 40px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  overflow-x: visible;
}

a.workhero-logo-container {
  align-self: flex-start;
}

/* button#menu-on-header {
  background-color: #ed3835;
  border: none;
  color: #fff;
  float: right;
  font-size: 16px;
  height: 40px;
  padding: 0;
} */

.workhero-logo {
  height: 35px;
  width: 175px;
  min-height: 35px;
  min-width: 175px;
  position: relative;
  top: -1px;
}

div.company-header-container {
  position: relative;
}

h3#company-on-header {
  align-self: center;
  display: inline-block;
  font-size: 22px;
  margin-bottom: 0;
  padding: 0 10px;
}

h3#company-on-header a {
  color: #fff;
}

div.company-header-container:hover .company-header-tooltip {
  transition-delay: 0.3s;
  visibility: visible;
}

span.company-header-tooltip {
  position: absolute;
  visibility: hidden;
  z-index: 10;
  width: 105px;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  text-align: center;
  border-radius: 4px;
  line-height: normal;
  padding: 6px 0;
  top: 50px;
  left: 70px;
}

.company-header-container span.company-header-tooltip::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 4px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
}

.header-secondary-actions {
  align-self: flex-end;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 150px;
  height: 100%;
}
.ant-popover.popover-card-help {
  z-index: 10;
}
.header-secondary-actions > #help-btn {
  color: #ffffff;
  background-color: #ed3835;
  padding-top: 5px;
  border: none;
  /* font-size: 25px; */
}

.header-secondary-actions > #help-btn > i {
  font-size: 25px;
}

.header-secondary-actions > #profile-dropdown-btn {
  background-color: #ed3835;
  border: none;
  color: #fff;
  font-size: 16px;
  height: 40px;
  padding: 0;
}

.pop-menu-header p a {
  color: inherit;
}

.account-options-popup p {
  cursor: pointer;
}

#help-menu .list {
  list-style: none;
  padding: 0;
  font-size: 14px;
}

#help-menu > .list > .item {
  padding: 5px;
  cursor: pointer;
}

#help-menu > .list > .item a {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}

#help-menu > .list > .item:hover,
#help-menu > .list > .item a:hover {
  color: #1890ff;
}

@media (max-width: 992px) {
  .header {
    justify-content: center;
  }
  .hide-md-down {
    display: none !important;
  }

  .header > .mobile-button {
    display: block;
  }

  .workhero-logo-container {
    margin: auto;
  }

  h3#company-on-header {
    display: none;
  }
}

@media (max-width: 480px) {
  .header-secondary-actions {
    width: 70px;
  }
}

.home-layout {
  height: calc(100% - 40px);
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden !important;
}

.universal-dashboard-home-link {
  display: flex;
  margin: 5px 20px;
  max-width: 220px;
}

.universal-dashboard-home-link .back-icon {
  margin: 4px;
}

.universal-metrics-container {
  min-height: 100%;
  /*  display: flex;
  justify-content: center;
  flex-direction: column; */
}
.demo-containers {
  box-shadow: #ccc 5px 3px 10px;
  background-color: #fff;
}

.super-metrics-container #movable-supercards {
  justify-content: center;
}

#movable-supercards .demo-containers {
  width: 29%;
  margin: 20px 2%;
  cursor: grabbing;
}
#fixed-supercards .demo-containers {
  width: 30%;
  margin: 20px 2%;
}
#movable-supercards .demo-containers:hover {
  cursor: grab;
}
.demo-containers h1,
.demo-number {
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 0;
}
p.demo-text {
  font-size: 1.5rem;
}
.demo-containers h1.green-metric {
  color: #22a89f;
}
.universal-options-container {
  display: flex;
  margin: 1em 2.5%;
}
span.note-intro-back {
  color: #aaa;
  font-size: 11px;
}

.universal-options-container {
  display: flex;
  justify-content: space-between;
}

.universal-options-container > .controls {
  text-align: left;
  display: flex;
}

.controls > div,
.controls > button {
  margin-right: 10px;
}

.universal-options-container > .company-select {
  display: flex;
  flex-direction: row;
}

.universal-options-container > .extra {
  display: flex;
  justify-content: end;
  align-items: center;
}

.universal-options-container > .extra > span,
.universal-options-container > .extra > div {
  margin-left: 2vw;
}

.active-role {
  padding-left: 10px;
}

.active-roles-tooltip {
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-width: 180px;
}

.small-metric {
  font-size: 16px;
  font-weight: 900;
}

.pickers-container {
  width: 100%;
  margin: 0 2.5%;
  text-align: left;
  min-height: 33px;
}
.chart-universal-metrics {
  min-height: 600px;
  width: 95%;
  margin: auto;
}
.chart-universal-metrics .ant-spin-nested-loading,
.chart-universal-metrics .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}

.chart-universal-metrics h1 {
  font-size: 24px;
  margin-bottom: 0;
}
div#universal-metrics-area-display {
  position: relative;
}
#universal-charts-options {
  display: inline-block;
  position: absolute;
  right: 0;
  top: -95px;
  margin-right: 2.5%;
  margin-top: 1rem;
}
.select-metrics-universal-charts {
  width: 200px;
}
.select-metrics-universal-charts .ant-select-selection {
  width: 200px;
}
.universal-range-picker .ant-calendar-ok-btn {
  display: none;
}
@media (max-width: 1200px) {
  .controls > div {
    margin-right: 1em;
  }

  .small-metric {
    max-width: 100px;
    word-break: break-word;
  }
}

@media (max-width: 992px) {
  #movable-supercards .demo-containers,
  #fixed-supercards .demo-containers {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .universal-options-container {
    flex-direction: column;
    width: inherit;
  }

  .universal-options-container > .controls,
  .universal-options-container > .extra {
    text-align: center;
    width: 100%;
    margin: 0.5em 0;
  }

  .universal-metrics-container div.flex {
    flex-direction: column;
  }
  #movable-supercards .demo-containers,
  #fixed-supercards .demo-containers {
    width: 100%;
    margin: 20px 0;
  }
  .demo-containers h1 {
    margin-bottom: 0;
  }
}

/* Just for bigger monitors, it we could use other rule this can change  */
@media (min-width: 1500px) {
  .demo-containers h1 {
    font-size: 3.5rem;
  }

  /* .universal-options-container {
    margin: 1em 4em 0 4em;
  } */
}

.login-form {
  width: 300px;
  margin: 5% auto auto auto;
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
  background-color: #ed3835;
  border: none;
  font-weight: bold;
}
.login-form-button:hover {
  background-color: #bd2a28;
  border: none;
}
.logo-login {
  width: 100%;
  margin-bottom: 5%;
}
.groups-card {
  width: 40%;
  margin: 5% auto;
}

.groups-modal {
  text-align: center;
}

.groups-card .ant-radio-group,
.groups-modal .ant-radio-group {
  text-align: left;
}
.groups-modal label.ant-radio-wrapper,
.groups-card label.ant-radio-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
}
a#forgotPass {
  position: relative;
  left: -95px;
  bottom: 5px;
}
.password-form {
  background-color: #fdfdfd;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  width: 350px;
  margin: 10% auto auto auto;
}
div#login-page {
  height: 100%;
  /* padding: 2em; */
}
div#login-columns-row {
  height: 100%;
}
div#login-form-container {
  width: 40%;
}
div#login-slider {
  width: 60%;
}

div#login-form-container > div {
  width: 300px;
}
.login-banner {
  top: 0;
  background-color: #ed3835;
  left: 0;
}

.banner-buffer {
  display: block;
  height: 1px;
  margin-bottom: 80px;
}

.login-banner h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: inherit;
  font-size: inherit;
  text-decoration: inherit;
  font-style: inherit;
  font-weight: inherit;
}

div#login-form-container div.alt-center a {
  color: #626365;
  font-size: 16px;
  margin: 0 2px;
}
div#login-form-container div.alt-center a:hover {
  text-decoration: underline;
}
/* #login-slider .slick-list , #login-slider .slick-track, #login-slider .slick-slide,
#login-slider .slick-slide div, #login-slider .slick-slide div > div{
  max-height: 400px;
} */
#login-slider .slick-slide div {
  max-height: 1000px;
}
#login-slider .slick-slide div img {
  max-height: 500px;
  margin: auto;
  width: 100%;
  height: auto;
  object-fit: contain;
}
#login-slider h2.login-slider-title {
  margin-top: 20px;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}

/**
* universal-dashboard customize login
*/
form#login-form-customize .ant-form-item-control-wrapper {
  display: inline-block;
}
form#login-form-customize .ant-form-item-control input[type='text'] {
  min-width: 400px;
}

.login-titles-section {
  margin: 10px;
  margin-top: 20px;
}

.dynamic-title {
  margin-top: 20px;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
}

.login-slider-inputs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.login-dynamic-titles-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 40px;
  margin-top: 40px;
}

.login-slider-dynamic-field {
  display: flex;
  flex-direction: row;
}

.login-slider-dynamic-field > img {
  max-height: 60px;
  max-width: 60px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.login-slider-dynamic-field input {
  width: 500px;
}

.login-slider-speed {
  margin: 9px;
}

.slider-images-section {
  margin: 10px;
  margin-bottom: 40px;
  padding: 20px;
  border: 2px dashed rgba(0, 0, 0, 0.2);
}

.slider-upload-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.slider-upload-container > .ant-upload-picture-card-wrapper {
  display: block;
  max-width: 500px;
}

.draggable-slider-url {
  max-width: 180px;
  max-height: 150px;
  min-height: 150px;
  min-width: 180px;
  margin: 2.5px;
  margin-top: 30px;
  position: relative;
}

.slider-url-card:hover {
  box-shadow: -1px 1px 10px 3px #e4e4e4;
}

.draggable-slider-url:hover .performance-card-close-icon {
  display: block;
}

.slider-url-card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -20px 0 30px 0;
}

.slider-url-card {
  min-width: 180px;
  min-height: 150px;
}

.slider-url-card:hover {
  cursor: grab;
}

.slider-url-card:active {
  cursor: grabbing;
}

.slider-url-card > .ant-card-body {
  height: 150px;
  width: 180px;
  padding: 0px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-url-img {
  display: block;
  max-height: 150px;
  max-width: 160px;
}

.customize-login-submit {
  display: flex;
  justify-content: center;
  margin: 60px;
}

.customize-login-submit > button {
  height: 60px;
  width: 300px;
  font-size: 20px;
}

.color-selector {
  display: 'flex';
  flex-direction: 'column';
}

.color-selector-rgba {
  display: 'flex';
  flex-direction: 'row';
  margin-top: 10px;
  font-weight: bold;
}

.color-selector-rgba .rgba-label {
  font-weight: normal;
  margin-left: 10px;
  margin-right: 5px;
}

.color-selector-rgba a {
  text-decoration: underline;
  font-weight: normal;
}

@media screen and (max-width: 1830px) {
  .login-slider-dynamic-field input {
    width: 400px;
  }
}
@media screen and (max-width: 1600px) {
  .login-slider-inputs {
    flex-direction: column;
  }
  .login-slider-dynamic-field input {
    width: 400px;
  }
}
@media screen and (max-width: 1024px) {
  div#login-columns-row {
    flex-direction: column;
  }
  div#login-form-container {
    order: 1;
    margin-bottom: 5%;
    width: 100%;
  }
  div#login-slider {
    order: 2;
    width: 100%;
  }

  #login-slider .slick-slide div {
    max-height: 300px;
  }
  #login-slider .slick-slide div img {
    max-height: 300px;
    margin: auto;
  }
  div#login-page {
    height: auto;
    background-color: #f0f2f5;
  }
  .login-slider-dynamic-field input {
    width: 250px;
    min-width: 250px !important;
  }
}

.profile-card {
  width: 60%;
  margin: 5% auto;
}
.ant-card.profile-card.company-info-container.ant-card-bordered .ant-row {
  margin-top: 1em;
}

.profile-card-actions {
  display: flex;
  justify-content: flex-end;
  margin-right: 53px;
}

.profile-card-actions button {
  margin-left: 24px;
}

.profile-container {
  height: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 2em;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.profile-sidebar {
  min-width: 240px;
  padding: 0 20px;
}

.profile-details-container {
  margin-bottom: 24px;
}

.profile-details {
  display: flex;
  flex-direction: column;
}

.profile-details > h2 {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 1.5em;
  line-height: 1.1666666666666667;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.profile-details p {
  font-weight: 500;
  font-size: 16px;
}

.profile-menu {
  text-align: left;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid #efefef;
}

.profile-menu > ul {
  border-right: none;
  font-weight: 400;
  font-size: 16px;
}

.profile-submenu-item {
  padding-left: 48px !important;
}

.sub-section-title {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 1em;
}

.profile-main {
  text-align: left;
  min-width: 700px;
  max-width: 700px;
  overflow-y: scroll;
}

.profile-main-header span {
  font-size: 2em;
}

.profile-main-content {
  padding: 0 24px;
  font-size: 16px;
  font-weight: 400;
}

.about-you-container {
  display: flex;
  flex-wrap: wrap;
}

/* .about-you-container > div {
  width: 50%;
  text-align: left;
  line-height: 1.5;
} */

.about-you-container .card {
  width: 100%;
  padding: 10px 16px;
  margin: 16px 0;
  border-radius: 3px;
  border: 2px solid #efefef;
}

.about-you-container .card .body {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}

.about-you-container .card h3 {
  margin: 16px 0;
  padding-left: 5px;
}

.about-you-container .card .body div {
  width: 50%;
}

.about-you-container .card .body h4 {
  font-size: 14px;
  font-weight: 500;
  color: rgb(107, 119, 140);
}

.about-you-container .card .body p {
  font-size: 16px;
  color: #000000;
}

@media (min-width: 1600px) {
  .profile-card {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .profile-container {
    height: inherit;
    flex-direction: column;
    justify-content: flex-start;
  }

  .profile-main {
    min-width: inherit;
    max-width: inherit;
  }
}

@media (max-width: 576px) {
  .profile-card {
    width: 80%;
  }

  .profile-card > .ant-card-body > .actions {
    flex-direction: column;
    height: 100px;
  }

  .about-you-container .card .body div {
    width: 100%;
  }
}

.facilities-card,
.management-card,
.companies-card {
  margin: 3% auto;
  width: 80%;
}

.facilities-card > .ant-card-body,
.management-card > .ant-card-body,
.companies-card > .ant-card-body {
  padding: 0;
  padding-bottom: 20px;
}

.facilities-table .ant-table-pagination.ant-pagination {
  margin-right: 16px;
}

.teams-table .ant-table-pagination.ant-pagination {
  margin-right: 16px;
}

.group-type-buttons-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.group-type-name-container {
  align-self: center;
  position: absolute;
  left: 50%;
  right: 50%;
}

.management-print-button {
  margin-left: 1rem;
}

.title-and-buttons-container-users-page {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 0 24px;
}

.users-container-users-page {
  align-self: center;
  position: absolute;
  left: 50%;
  right: 50%;
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0, 0.85);
}

.facilities-card-users {
  width: 80%;
  margin: 3% auto;
}

.facilities-card-users > .ant-card-body {
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
}

span.ant-badge-status-dot.ant-badge-status-yellow,
span.ant-badge-status-dot.ant-badge-status-red,
span.ant-badge-status-dot.ant-badge-status-green {
  border-radius: 0;
}

.filter-settings-footer {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  border-top: 1px solid #e8e8e8;
}

.transparent-button {
  background-color: transparent;
  border: none;
  color: #1890ff;
  box-shadow: none;
}

#facilities-table,
#teams-table,
#users-table,
.facilities-card .ant-spin-nested-loading,
.facilities-card-users .ant-spin-nested-loading,
.ant-table-wrapper.facilities-table,
.ant-table-wrapper.teams-table,
.ant-table-wrapper.users-table {
  height: 620px;
}
.loading-table-height {
  height: 656px;
}

.ant-modal-body form#CreateUserForm,
.ant-modal-body form#CreateTeamForm {
  max-height: 400px;
  overflow-y: scroll;
}
.ant-modal.CreateTeamModal {
  padding-bottom: 0;
}
form#CreateUserForm::-webkit-scrollbar-thumb,
.ant-modal-body form#CreateTeamForm::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  width: 10px !important;
}
form#CreateUserForm::-webkit-scrollbar,
.ant-modal-body form#CreateTeamForm::-webkit-scrollbar {
  width: 10px !important;
  background-color: #efefef;
}
@media (max-width: 1200px) {
  .td-actions > button,
  .td-actions > a {
    width: 80%;
    margin: 1rem 0 !important;
  }
}

@media (max-width: 768px) {
  .facilities-table thead {
    display: none;
  }

  .facilities-table tr {
    display: flex;
    flex-direction: column;
  }

  .facilities-table td {
    padding-left: 50%;
  }

  .facilities-table td:nth-of-type(1):before {
    content: 'Name: ';
  }
  .facilities-table td:nth-of-type(2):before {
    content: 'Enable ';
  }
  .facilities-table td:nth-of-type(3):before {
    content: 'Actions ';
  }

  .td-actions > button {
    width: 100%;
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 1600px) {
  .ant-modal-body form#CreateTeamForm,
  .ant-modal-body form#CreateUserForm {
    max-height: none;
    overflow-y: auto;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1820px) {
  .ant-modal-body form#CreateUserForm {
    max-height: 600px;
    overflow-y: auto;
  }
}
@media screen and (min-width: 1821px) {
  .ant-modal-body form#CreateUserForm {
    max-height: 700px;
    overflow-y: auto;
  }
}

aside.sider-container {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  background-color: #fff;
}

.side-menus {
  height: 95%;
  width: 100%;
  background-color: white;
  text-align: left;
  overflow-x: hidden;
}
.side-menu-bar {
  height: 100%;
  background-color: #fff;
}
.side-menu-bar > .mobile-button {
  display: none;
  position: absolute;
  top: -40px;
  left: 25px;
  height: 40px;
  background-color: #ed3835;
  color: white;
  font-weight: bold;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.side-menu-bar > .mobile-button:hover {
  border: none;
  outline: none;
}

.side-menu-bar > .mobile-button:focus {
  outline: none;
}

ul.menu-sidebar-options.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
ul.menu-sidebar-options.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 27px !important;
}
.menu-sidebar-options {
  border: none;
  width: 100%;
  /* height: 100%; */
  padding-left: 5px;
  padding-bottom: 37px;
}

.avatar-sidebar-menu {
  background-color: #000;
  font-weight: bold;
}
button.collapsed-navigation {
  width: 100%;
  height: 5%;
  background-color: #ed3835;
  border: 1px solid #ed3835;
  outline: none;
  position: absolute;
  bottom: 0;
  left: 0;
}
button.collapsed-navigation i {
  color: #fff;
  font-size: 18px;
}
ul.ant-menu.menu-sidebar-options.ant-menu-inline-collapsed {
  padding-left: 0;
}
div#company-logo-container {
  width: 190px;
  height: auto;
  min-height: 90px;
  margin: 8px auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.company-logo {
  vertical-align: middle;
  align-self: center;
  border-style: none;
  max-width: 170px;
  height: auto;
  max-height: 90px;
}

div.add-company-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 150px;
  height: 80px;
  color: rgba(0, 0, 0, 0.2);
  margin: 18px auto;
  text-align: center;
  border: 2px dashed rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

div#collapsed-add-logo {
  width: 40px;
  height: 40px;
}

div.add-company-logo-container:hover,
div#collapsed-add-logo:hover {
  color: rgba(0, 0, 0, 0.65);
  border-color: rgba(0, 0, 0, 0.5);
}

div.add-company-logo-container > p {
  margin-bottom: 4px;
}

.company-logo-tooltip,
.add-logo-tooltip {
  position: absolute;
  visibility: hidden;
  z-index: 1;
  width: 105px;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 6px 0;
}

.company-logo-tooltip {
  top: 36px;
  left: 190px;
}

.add-logo-tooltip {
  top: 40px;
  left: 190px;
}

.company-logo-tooltip::before,
.add-logo-tooltip::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;

  border-top: 3.5px solid transparent;
  border-bottom: 3.5px solid transparent;
  border-right: 3.7px solid rgba(0, 0, 0, 0.75);
  left: -3.7px;

  top: 12px;
}

#company-logo-container:hover .company-logo-tooltip,
.add-company-logo-container:hover .add-logo-tooltip {
  transition-delay: 0.3s;
  visibility: visible;
}

aside.ant-layout-sider-collapsed div#company-logo-container {
  max-width: 70px;
}

aside.ant-layout-sider-collapsed
  div#company-logo-container
  > .company-logo-tooltip {
  top: 35px;
  left: 82px;
}

div#collapsed-add-logo > .add-logo-tooltip {
  top: 22px;
  left: 82px;
}

.side-submenu-title {
  font-weight: bold;
}
.menu-item-action-icon {
  display: none;
  z-index: 10;
  position: absolute;
  margin-left: -5px;
}

.ant-menu-item:hover > .menu-item-action-icon {
  display: initial;
}

.side-menu-item-icon {
  margin-left: 14px;
}

.menu-item-new-feature {
  color: rgb(237, 56, 53);
  padding: 2px 4px;
  border-radius: 4px;
  background-color: rgba(237, 56, 53, 0.1);
}
.menu-item-new-feature:hover {
  cursor: pointer;
}
#fav-key-container {
  z-index: 11;
  padding: 4px 4px 4px 8px;
  text-align: left;
  width: 100%;
  background: rgb(255, 255, 255);
  position: absolute;
  left: 0;
  bottom: 5%;
}

@media (max-width: 992px) {
  .sider-container {
    position: absolute;
    z-index: 1;
    height: 100%;
  }

  .side-menu-bar > .mobile-button {
    display: block;
  }
}

.side-profile{
    width: 100%;
    background-color: white;
    text-align: center;
    height: 20%;
    color: black;
}

.profile-entry {
    align-items: center;
    width: 100%;
    padding: 10px 10px 10px;

    margin-bottom: 50px;
    word-wrap: break-word
}


/**
For Tablet Size like iPad Pro
*/
@media only screen
and (max-width: 993px) {
    .profile-entry-name {
        font-size: xx-small;
    }
    .profile-entry-position {
        font-size: xx-small;
    }
}
.workers-table-wrapper {
  height: auto;
  width: 100% !important;
  flex: 0 0 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  background-color: #fff;
}
.workers-table-wrapper.ant-layout-sider-collapsed {
  height: auto;
  width: 0 !important;
  flex: 0 0 !important;
  max-width: 0 !important;
  min-width: 0 !important;
}

.workers-table-wrapper.ant-layout-sider-collapsed div.ant-card-head,
.workers-table-wrapper.ant-layout-sider-collapsed div.ant-card-body {
  padding: 0;
}
.workers-table-wrapper .ant-table-row-cell-break-word .ant-table-column-title {
  word-break: initial;
}
.workers-table-container {
  overflow-x: hidden;
}
.workers-table-container tr.ant-table-row-selected td,
.workers-table-container tr.ant-table-row-selected > td.ant-table-column-sort {
  background-color: #e6f7ff;
}
.workers-table-container th.ant-table-selection-column,
.workers-table-container td.ant-table-selection-column {
  display: none;
}
.workers-table-container .ant-table-row-level-0 td:nth-child(2) {
  width: 140px;
}
.workers-card-container {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
}

.workers-card-title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.workers-card-header-and-shortcut {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.workers-card-title-wrapper p {
  font-size: large;
  color: #565656;
  font-weight: bold;
  margin: 0;
}

.table-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-radius: 10px;
}

div.table-wrapper::-webkit-scrollbar {
  width: 17px;
}

button.collapse-associates-performance {
  background-color: #000;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 2px;
  position: relative;
}

button.collapse-associates-performance:focus {
  outline: none;
}

button.collapse-associates-performance i {
  color: #fff;
  font-size: 18px;
  background-color: #ed3835;
  position: absolute;
  left: -12px;
  padding: 5px;
  z-index: 2;
  border-radius: 50%;
}

#performance-all-associates {
  background-color: #ed3835;
  padding: 5px 10px;
  color: #fff;
  height: auto;
  font-weight: bold;
  border: none;
  width: min-content;
}

#associates-sidebar-shortcut-table {
  background-color: #fff;
  color: #ed3835;
  border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
}

#associates-sidebar-shortcut-table:hover {
  background-color: #e61714;
  color: #fff;
}

.workers-card-title-wrapper button#performance-all-associates {
  border-radius: 0 !important;
}
.popup-associates-data {
  padding: 5px 10px;
}
.popup-associates-data .ant-radio-wrapper {
  display: block;
}
/* .workers-table-container th.associates-data-title {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
} */
td.red-zone-metric,
.red-zone-metric .ant-statistic-content-value,
.red-zone-metric .ant-statistic-content-prefix {
  color: #e61714 !important;
}
td.green-zone-metric,
.green-zone-metric .ant-statistic-content-value,
.green-zone-metric .ant-statistic-content-prefix {
  color: #4fa800 !important;
}
td.yellow-zone-metric,
.yellow-zone-metric .ant-statistic-content-value,
.yellow-zone-metric .ant-statistic-content-prefix {
  color: #ef9b0f !important;
}

td.associates-list-cell {
  font-weight: bold;
}

td.no-colored-zone {
  position: relative;
}
td.no-colored-zone:before {
  content: 'Please set target metrics under settings';
  position: absolute;
  font-size: 9px;
  top: -25px;
  background-color: #ccc;
  border-radius: 5px;
  padding: 2px;
  color: #000;
  right: 10px;
  z-index: 5;
  display: none;
}

td.no-colored-zone:hover:before {
  display: block;
}
div.workers-table-container tbody td.associates-data-title {
  word-break: break-all;
}
div.workers-table-container thead .ant-table-header-column,
div.workers-table-container thead .ant-table-column-sorters {
  width: 95%;
}
thead
  th.associates-data-title
  i.anticon.anticon-edit.ant-table-filter-icon.ant-dropdown-trigger:hover,
thead
  th.associates-data-title
  i.anticon.anticon-search.ant-table-filter-icon.ant-dropdown-trigger:hover {
  background-color: transparent;
}
thead
  th.associates-data-title
  i.anticon.anticon-edit.ant-table-filter-icon.ant-dropdown-trigger,
thead
  th.associates-data-title
  i.anticon.anticon-search.ant-table-filter-icon.ant-dropdown-trigger {
  height: 50%;
  background-color: transparent;
}
div.workers-table-container
  thead
  th.associates-data-title.ant-table-column-has-actions {
  width: 25%;
  padding-right: 0 !important;
  min-height: 45px;
}
thead
  th.associates-data-title
  div.ant-table-column-sorter-inner.ant-table-column-sorter-inner-full {
  top: 10px;
  position: relative;
  margin-left: 0;
  right: 5px;
}

@media (max-width: 1200px) {
  button.collapse-associates-performance {
    display: none;
  }
  .ant-col.performance-page-col.collapsedAssociates {
    width: 100%;
  }
  .workers-table-wrapper.ant-layout-sider-collapsed {
    height: auto;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
@media screen and (max-width: 1440px) {
  thead
    th.associates-data-title
    div.ant-table-column-sorter-inner.ant-table-column-sorter-inner-full {
    right: 0;
  }
}
@media screen and (min-width: 1460px) {
  div.workers-table-container thead .ant-table-header-column,
  div.workers-table-container thead .ant-table-column-sorters {
    width: 100%;
  }
}

.performance-page-wrapper {
  height: 100%;
  width: 100%;
}

.performance-page-row {
  height: 100%;
  width: 100%;
}

.performance-page-col {
  height: 100%;
  display: flex;
  /*  justify-content: center;
  align-items: center; */
}
.performance-single-day-picker .ant-calendar-time-picker-btn,
.performance-range-date-picker .ant-calendar-ok-btn {
  display: none !important;
}
.performance-single-day-picker .ant-calendar-ok-btn {
  visibility: hidden;
}

div#performance-settings-container div.ant-col {
  height: 100%;
}
div#performance-settings-container {
  background-color: #fff;
  margin: 2.5px;
  padding: 10px;
  width: 99.5%;
}
#refresh-rate-selector {
  display: block;
  margin: 0.5rem 0;
}
#help-performance {
  max-width: 26px !important;
  min-width: 26px !important;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
}
#performance-settings-container span.past-averages {
  width: 100%;
}
#get-performance-metrics-button {
  /* padding: 0 5px;  WH-801 Committing out to make room for loading spinner on Get Metrics button*/
  margin-left: 2px;
}
ul.slick-dots.custom-dots-slider {
  top: -2px;
  height: 30px;
}
@media screen and (max-width: 1380px) {
  #get-performance-metrics-button {
    margin-top: 0.5rem;
    margin-left: 0;
  }
}
/*
span#last-updated-performance {
  width: max-content;
  position: absolute;
  top: 5px;
  margin-left: 10px;
} */
.slider-buttons {
  height: 10px;
  width: 10px;
  padding: 0;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 50%;
  border-color: gray;
}
.slider-buttons:hover {
  background-color: #ef9593;
}
.dot-red {
  background-color: #ed3835;
}
.dot-grey {
  background-color: #f1f1f1;
}

/* .card-title-wrapper {
  width: 100%;
  display: flex;
} */

.card-content-wrapper {
  height: 100%;
  width: 100%;
  border-radius: 0 0 6px 6px;
  overflow-y: auto;
  overflow-x: hidden;
}

.card-content-wrapper .ant-spin-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/**
Card Title
 */
/* .card-title-infos {
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 6px 6px 0 0;
}

.card-title-infos-left {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding-left: 10px;
}

.card-title-infos-right {
  height: 100%;
  width: 50%;
  padding-right: 10px;
} */

/**
Card DataContent
 */
.card-list-responsive-container,
.card-list-responsive-container > .ant-spin-nested-loading,
.card-list-responsive-container .ant-spin-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.card-list-responsive-container .ant-row {
  height: min-content;
}

.estimated-metrics-container {
  width: 100%;
  display: flex;
  border-top: 4px solid #000;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

.estimated-metrics-container .ant-statistic-content {
  font-family: Arial, Helvetica, sans-serif;
}

.performance-card-container,
.static-card-container {
  max-height: 200px;
  min-height: 200px;
  border: none;
  margin: 2.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.static-card-marker {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  z-index: 10;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #1890ff;
  top: 0px;
  right: 0px;
}

.static-card-marker-teamRanking {
  border-right: 15px solid #fdb44d;
}
.static-card-container .ant-card-head-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  padding: 0;
  padding-top: 12px;
}

.static-card-container .ant-card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  min-height: 140px;
  font-weight: bold;
}

.static-card-container .ant-card-body::before,
.static-card-container .ant-card-body::after {
  display: none;
}

.static-card-chart-container {
  height: 135px;
  width: 60%;
  max-width: 16vw;
}

.rankings-chart-container {
  height: 135px;
  width: 100%;
}

.rankings-chart-container p {
  margin-top: 50px;
  font-style: italic;
}

.narrow-chart-container {
  height: 158px;
  width: 50%;
  max-width: 15vw;
}

.time-utilization-card-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}

.bar-graph-chart-container {
  height: 150px;
  width: 60%;
  max-width: 15vw;
  position: relative;
}

.time-utilization-card-container .bar-graph-chart-container {
  order: 1;
  height: 120px;
  width: 50%;
  max-width: 15vw;
}

.static-card-secondary-text {
  margin: auto 0;
}

.static-card-container .time-utilization-card-secondary-text {
  order: 2;
  padding-bottom: 0px;
  display: flex;
  width: fit-content;
  flex-direction: column;
  position: relative;
}

.pick-time-container {
  margin-top: 5%;
}

.non-pick-time-container {
  margin-bottom: 5%;
  padding-bottom: 5%;
}

.time-utilization-card-secondary-text-total-hours {
  order: 3;
  color: black;
  font-size: medium;
  display: flex;
  position: absolute;
  top: 92.5%;
  width: 100%;
  height: 25%;
}

.total-working-hours-container {
  border-top: 1px solid black;
  position: absolute;
  top: 70%;
}

.static-card-container h2 {
  margin: auto;
  align-self: flex-end;
  font-size: 40px;
  font-weight: bold;
}

.static-card-container > .ant-card-body > h2 {
  margin: auto 0;
  margin-top: 20px;
}

.static-card-secondary-text h2 {
  margin: auto;
  max-height: 50px;
  width: fit-content;
  font-size: 40px;
  font-weight: bold;
}

.static-card-secondary-text h3 {
  font-size: 18px;
  margin-top: 3px;
}

.time-utilization-card-secondary-text {
  padding-bottom: 10px;
  align-items: end;
}

.time-utilization-card-secondary-text span {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.time-utilization-card-secondary-text h2 {
  font-size: 30px;
  margin-right: 0.3vw;
}

.time-utilization-card-secondary-text h3 {
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  align-self: flex-end;
  margin-bottom: 5px;
}

.static-card-secondary-text a {
  width: 100%;
}

.hover-shade-div {
  padding: 7px;
  padding-top: 0px;
  margin-right: 5px;
  max-width: 7vw;
}

.hover-shade-div:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* this rule refers to the Estimated Hours Saved and Estimated ROI cards in footer of Performance page */
.value-added-card-container {
  max-height: 100%;
  min-height: 100%;
  /*  border: none;
  margin: 2.5px; */
}

.static-card-container:hover {
  cursor: pointer;
}

.performance-card-container:hover,
.static-card-container:hover {
  box-shadow: -1px 1px 10px 3px #e4e4e4;
}
.performance-card-container .ant-card-head,
.value-added-card-container .ant-card-head,
.static-card-container .ant-card-head {
  border-bottom: none;
  min-height: 0px;
}
.performance-card-container .ant-card-body,
.value-added-card-container .ant-card-body {
  padding: 0;
  min-width: 100%;
}

.performance-card-container .ant-card-head-title,
.value-added-card-container .ant-card-head-title {
  padding-bottom: 0;
  padding: 0px;
}

.performance-card-container .ant-statistic-title,
.performance-card-container .ant-statistic-content {
  font-family: Arial, Helvetica, sans-serif;
  display: inline-flex;
  color: rgb(42, 42, 42);
  font-size: 20px;
  margin-right: 4px;
  margin-bottom: 0px;
}

.performance-card-subfields-container .ant-statistic-content-value-int {
  font-size: 20px;
}

.ant-statistic .ant-statistic-content-value-decimal {
  font-size: 55px;
  font-weight: bold;
}

.dynamic-average-container .ant-statistic-content-value-decimal,
.static-goal-container .ant-statistic-content-value-decimal {
  font-size: 20px;
}

.performance-card-subfields-container .ant-statistic-content-value-decimal,
.performance-card-subfields-container .ant-statistic-content-prefix {
  font-size: 20px;
}

.ant-statistic-content-prefix {
  margin-right: 0px;
}

.ant-statistic-content-suffix {
  font-size: 20px;
  margin-left: 0px;
}
.estimated-metrics-container .value-added-card-container .ant-card-head {
  height: 28px;
  min-height: 28px;
}
.performance-select-facility-day,
.performance-select-facility-range {
  min-width: 200px;
  max-height: 32px;
}

/* span#carousel-tooltip {
  position: absolute;
  z-index: 2;
  background-color: #efefef;
  width: 150px;
  right: 50%;
  padding: 3px 5px;
  bottom: -70px;
  display: none;
} */
.performance-select-facility-day:after {
  display: none;
  content: 'Select multiple groups (up to 5) to enable Performance Carousel';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5px 8px;
  z-index: 2;
  top: 40px;
  color: #fff;
  border-radius: 5px;
}
.performance-select-facility-day:hover:after {
  display: block;
}

.performance-select-facility-range:after {
  display: none;
  content: 'Select a single group';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5px 8px;
  z-index: 2;
  top: 40px;
  color: #fff;
  border-radius: 5px;
}
.performance-select-facility-range:hover:after {
  display: block;
}

.performance-range-selector > .ant-select-selection,
.performance-select-facility-range > .ant-select-selection,
.performance-select-facility-day > .ant-select-selection {
  width: 195px;
}

.py-1 > .ant-calendar-picker {
  width: 195px;
}

.performance-select-facility-range > .ant-select-selection,
.performance-select-facility-day > .ant-select-selection {
  max-height: 35px;
}

.performance-range-selector-dropdown > div > ul {
  max-height: none;
}

.performance-range-selector:after {
  display: none;
  content: 'Select a time range';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5px 8px;
  z-index: 2;
  top: 40px;
  color: #fff;
  border-radius: 5px;
}

.performance-range-selector:hover:after {
  display: block;
}

/* .ant-col {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
} */

/* fine-tuning margin for middle column to align with left and right cols */
#performance-settings-container > div:nth-child(2) > h2 {
  margin-bottom: 8.75px;
}

#performance-settings-container > .alt-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

/* .past-averages {
  height: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
} */
.goal-progress-bar.ant-progress .ant-progress-text {
  width: auto;
}
.ant-col.performance-page-col.augmentMetrics {
  width: calc(100% - 5px);
}
div#row-metrics-performance {
  overflow-y: hidden !important;
}
.ant-col.performance-page-col.collapsedAssociates {
  width: 5px;
}
.ant-col.performance-page-col {
  transition: all 0.5s;
}

.ant-row.with-past-averages::before {
  display: table;
  content: none;
}

.ant-row.with-past-averages::after {
  display: table;
  content: none;
}

.with-past-averages {
  display: flex;
  align-items: center;
  width: inherit;
  justify-content: space-evenly;
}

.with-past-averages > div:nth-child(1) {
  max-width: inherit;
}

.with-past-averages > div:nth-child(2) {
  max-width: inherit;
}

.past-averages2-div {
  box-shadow: 2px 2px 10px #ccc;
  padding: 5px;
  height: fit-content;
}

.past-averages2-div p,
.past-averages2-div h3 {
  margin-bottom: 0;
}
.past-averages2-div i {
  font-size: 1rem;
  padding: 5px;
  border-radius: 8px;
}
.past-averages2-div.down-bg i {
  background-color: #ffa4a9;
  color: #f5222d;
}

.past-averages2-div.up-bg i {
  background-color: #a4da8a;
  color: #50ab00;
}

.past-averages2-div.equal-bg i {
  background-color: #fde9a2;
  color: #fdb44d;
}

#seconds-scan .past-averages2-div.up-bg i,
#steps-scan .past-averages2-div.up-bg i,
#cost-pscan .past-averages2-div.up-bg i {
  background-color: #ffa4a9;
  color: #f5222d;
}

#seconds-scan .past-averages2-div.down-bg i,
#steps-scan .past-averages2-div.down-bg i,
#cost-pscan .past-averages2-div.down-bg i {
  background-color: #a4da8a;
  color: #50ab00;
}

.drag-start {
  opacity: 0;
}

.drag-enter {
  opacity: 0.5;
  transform: scale(0.9);
}
.performance-card-container::after,
.time-utilization-card::after {
  content: 'Click for charts, Drag to move';
  font-size: 11px;
  background-color: #f1f1f1;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0 2px;
  opacity: 0.6;
  display: none;
}
.performance-card-container:hover::after,
.time-utilization-card:hover::after {
  display: block;
}

.perf-modal-card {
  height: 200px;
  margin: 2.5px;
  width: 32.9%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
  border: 2px dashed rgba(0, 0, 0, 0.2);
}

.perf-modal-icon {
  font-size: 5em;
  color: rgba(0, 0, 0, 0.2);
}

.perf-modal-icon:hover {
  color: rgba(0, 0, 0, 0.5);
}

.draggable-card {
  position: relative;
}

.performance-card-close-icon {
  position: absolute;
  display: none;
  left: 10px;
  top: 10px;
  color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 1.5em;
  transition: 0.1s;
}

.performance-card-close-icon:hover {
  color: rgba(0, 0, 0, 0.5);
}

.draggable-card:hover .performance-card-close-icon {
  display: block;
}

.draggable-card:hover .perf-modal-card + .performance-card-close-icon {
  display: none;
}

aside#estimated-cards-sider {
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  padding: 0;
  background: #ffffff;
}
aside#estimated-cards-sider.collapse-estimated-cards-sidebar {
  height: 2px;
  max-height: 2px;
  border-top: 4px solid #000;
}
aside#estimated-cards-sider.no-collapse-estimated-cards-sidebar {
  height: 150px;
  max-height: 150px;
}

aside#estimated-cards-sider.collapse-estimated-cards-sidebar
  .estimated-metrics-container {
  display: none;
}
aside#estimated-cards-sider .ant-layout-sider-trigger {
  position: absolute;
  top: -1px;
  background-color: red;
  width: 30px !important;
  border-radius: 50%;
  height: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

aside#estimated-cards-sider .ant-layout-sider-trigger i.anticon {
  font-size: 20px;
  transform: rotate(270deg);
  vertical-align: inherit;
  position: relative;
  top: -5px;
}

.slick-slider.w-100.main-slider-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden !important;
  overflow: initial;
}
div.performance-page-col .slick-list,
div.performance-page-col .slick-track,
div.performance-page-col .slick-slide,
div.performance-page-col .slick-slide > div {
  height: 100% !important;
}

@media (max-width: 768px) {
  .card-title-infos-left,
  .card-title-infos-right {
    width: 100%;
  }
  .card-title-infos-left {
    align-items: center;
    text-align: center;
  }
  .card-title-infos {
    flex-direction: column;
  }
  .performance-select-facility {
    float: none;
    margin-right: 0;
  }
  .past-averages {
    justify-content: center;
  }
}

/** Rule for small screens and below */
@media (max-width: 576px) {
  .estimated-metrics-container {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  aside#estimated-cards-sider.no-collapse-estimated-cards-sidebar {
    height: 180px;
    max-height: 180px;
  }
}

@media (min-width: 2100px) {
  .static-card-chart-container {
    max-width: 15vw;
  }

  .bar-graph-chart-container {
    max-width: 13vw;
  }

  .narrow-chart-container {
    max-width: 10vw;
  }
}

@media (min-width: 2800px) {
  .static-card-chart-container {
    max-width: 13vw;
  }

  .bar-graph-chart-container {
    max-width: 12vw;
  }

  .narrow-chart-container {
    max-width: 8vw;
  }
}

.ant-modal-wrap {
  height: 100%;
  width: 100%;
}

.ant-modal-content {
  height: 100%;
}

.chart-page-wrapper {
  width: 100%;
  /* overflow: scroll;
    height: 100%; */
}

.card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-comp-container {
  width: 95%;
  height: 75%;
  border: 1px solid #ccc;
  /* box-shadow: 5px 10px 10px #ccc;*/
  border-radius: 10px;
}

.chart-options-card,
.data-sets-card {
  width: 95%;
  margin: 2rem auto 1rem auto;
  box-shadow: none !important;
}

.card-border {
  border: 1px solid #ccc;
  border-radius: 10px;
}

.chart-options-container {
  display: flex;
  justify-content: space-between;
}

.data-sets-container {
  display: flex;
  align-items: center;
  max-width: 60%;
}

.charts-print-export-container {
  margin-top: 0.5rem;
}

.datasets-and-export-buttons {
  display: flex;
  justify-content: space-between;
  max-width: 80vw;
}

.data-sets-tags-container {
  display: flex;
  flex-flow: row wrap;
  max-width: 80%;
  z-index: 10;
  align-items: start;
  height: 32px;
}

.data-set-tag {
  margin-top: 1rem;
}

.charts-select-team {
  margin-right: 10px;
  max-width: 300px;
  min-width: 150px;
}

.data-type-container,
.chart-date-range-container,
#chart-point-labels-radio,
#chart-omit-null-checkbox,
#chart-show-targets-checkbox {
  display: flex;
  align-items: center;
}

.charts-input-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  max-width: 85vw;
  grid-row-gap: 2vh;
  row-gap: 2vh;
  padding-left: 2vw;
}

.charts-input-row span.ant-radio + * {
  padding-left: 6px;
}

.charts-input-row .ant-radio-wrapper {
  margin: 0;
}

.charts-input-row .ant-checkbox-wrapper {
  margin-left: 8px;
}

.charts-input-row .chart-form-label {
  margin-left: 1vw;
}

.chart-form-label {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

.g2-tooltip {
  background-color: #fff;
  box-shadow: 2px 2px 10px 2px #ccc;
}

ul.g2-tooltip-list {
  list-style: none;
  text-align: left;
  padding: 10px 15px;
  margin: 0;
}
.g2-tooltip p {
  margin-bottom: 0;
  padding-top: 10px;
}
ul.g2-tooltip-list span.g2-tooltip-name {
  padding-right: 1rem;
}

.collapsed-menu-charts {
  position: absolute;
  z-index: 10;
  width: 200px;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: -2px 3px 5px #ccc !important;
}
.customEndPicker .ant-calendar-footer-extra {
  float: left;
}
.customEndPicker .ant-calendar-footer-extra button {
  border: none;
  color: #1890ff;
  background-color: #ffffff;
  cursor: pointer;
  height: 35px;
}
.radios-charts-hrs,
.radios-charts-solos {
  text-align: left;
}
.radios-charts-hrs {
  width: auto;
  margin-right: 10px;
}
.ant-radio-group.ant-radio-group-outline.radios-charts-hrs label,
.radios-charts-solos label {
  display: block;
  margin-right: 0;
}
.customEndPicker a.ant-calendar-ok-btn,
.customStartPicker a.ant-calendar-ok-btn {
  display: none;
}

.over-time {
  line-height: normal;
  /* padding: 3px; */
  height: fit-content;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.over-time > .over-time-value {
  display: inline-block;
}

.over-time > .badge {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
}

.badge::before {
  content: ' ';
}

.over-time i {
  margin-left: 5px;
  padding-right: 2px;
  font-size: 1rem;
  border-radius: 8px;
}

.over-time.negative.down-bg {
  color: #50ab00;
}

.over-time.negative.up-bg {
  color: #f5222d;
}

.over-time.down-bg {
  color: #f5222d;
}

.over-time.up-bg {
  color: #50ab00;
}

.over-time.equal-bg {
  color: #fdb44d;
}
div#popOvertime.ant-popover-inner {
  background-color: rgba(255, 255, 255, 0.4);
  font-weight: bold;
  border: 1px solid;
}

.presets-popover {
  padding: 0;
}

.presets-popover .ant-popover-inner-content {
  padding: 0;
}

.presets-select-item {
  width: 100%;
  padding: 12px;
  /* margin: 0 12px; */
}

.presets-select-delete-btn {
  position: absolute;
  right: 5px;
  z-index: 5px;
}

.presets-arrow {
  float: right;
  font-size: 10px;
  margin-top: 5px;
  transform-origin: 50% 50%;
}

.presets-arrow > svg {
  transition: transform 0.3s;
}

.presets-arrow-open > svg {
  transform: rotate(180deg);
}

.chart-line-container {
  height: 650px;
  min-height: 650px;
  width: 100%;
  margin: auto;
}
.charts-table-container {
  margin-right: auto;
  margin-left: auto;
  margin-top: 4vh;
  width: 85%;
}

.charts-table thead > tr > th {
  background-color: black;
  color: whitesmoke;
  font-weight: 700;
}

.charts-table thead > tr > th:hover {
  cursor: pointer;
}

#chart-only .ant-tabs-tab-prev-icon,
#chart-only .ant-tabs-tab-next-icon {
  opacity: 0;
}

.datepicker-on-charts .ant-calendar-selected-day .ant-calendar-date,
.weekpicker-on-charts
  .ant-calendar-week-number
  .ant-calendar-body
  tr.ant-calendar-active-week {
  /* background: transparent; */
  font-weight: normal;
}
.weekpicker-on-charts
  .ant-calendar-week-number
  .ant-calendar-body
  tr.ant-calendar-current-week {
  /* background: #bae7ff; */
}
.monthpicker-on-charts
  .ant-calendar-month-panel-selected-cell
  .ant-calendar-month-panel-month {
  background: transparent;
  color: #000;
}
.monthpicker-on-charts .ant-calendar-month-panel-month {
  padding: 0;
}
.monthpicker-on-charts
  .ant-calendar-month-panel-cell-disabled
  .ant-calendar-month-panel-month,
.monthpicker-on-charts
  .ant-calendar-month-panel-cell-disabled
  .ant-calendar-month-panel-month:hover {
  background: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
div.hours-picker-wrap div.hours-picker-wrap-fields {
  display: inline;
}
div.hours-picker-wrap div.hours-picker-wrap-fields span.ant-time-picker {
  width: 80px;
}
.ant-select-dropdown.detail-tables-treeselect.ant-select-tree-dropdown.ant-select-dropdown--multiple {
  max-height: 350px !important;
}
div.detail-tables-treeselect span.ant-select-tree-switcher {
  display: none;
}
div.detail-tables-treeselect .ant-select-tree li ul {
  padding: 0;
}
#tables-details-form .ant-card-body {
  display: flex;
  flex-direction: column !important;
  align-items: center;
}
.ant-modal.details-confirmation-modal .ant-modal-content {
  overflow-y: scroll;
  max-height: 80vh;
}
#sum-avg-table-charts .ant-table-column-title:hover {
  cursor: pointer;
}
.charts-table-hover-cell:hover {
  cursor: pointer;
}

.form-input-label {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 3px;
  height: 32px;
}

.rankings-group-select {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.rankings-group-select .ant-select {
  width: 50%;
}

@media (max-width: 1280px) {
  .chart-options-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .data-type-container {
    justify-content: flex-start;
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 1190px) {
  #chart-only .ant-tabs-tab-prev-icon,
  #chart-only .ant-tabs-tab-next-icon {
    opacity: 1;
  }
}
/* @media screen and (max-width: 1650px) {
  .card-comp-container {
    height: 90%;
  }
}
 */

.associates-table-title {
  display: flex;
  justify-content: center;
  align-content: center;
}

.associates-table-title h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.associates-table-selector-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  height: min-content;
  padding-bottom: 15px;
}

#associates-table
  .ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 12px !important ;
}

.summary-table-container {
  border-top: 1px solid black;
  position: sticky;
  bottom: 0;
}

.associate-table-range-view-selector {
  display: inline-flex;
}

.associates-table-group-date-selector .ant-calendar-picker {
  width: 195px;
}

.associates-table-group-date-selector {
  display: flex;
  align-items: center;
}

#associates-table thead > tr > th {
  background-color: black;
  color: whitesmoke;
}

.get-overview-container,
.download-csv-container {
  margin-left: 15px;
}

#associates-table
  .ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-sorters
  .ant-table-filter-icon:hover {
  background-color: black;
  color: #1890ff;
}

#associates-table
  .ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-sorters
  .ant-table-filter-icon {
  height: 1.2rem;
  width: 1rem;
  background-color: black;
}

#associates-table span.ant-table-column-title {
  font-size: 0.7rem;
  position: relative;
}

#associates-table span.ant-table-column-sorter {
  position: absolute;
  margin-left: 0;
  bottom: 8px;
  right: 0px;
}

#associates-table .ant-table-thead > tr > th .ant-table-filter-icon > svg {
  position: absolute;
  top: 45%;
  left: 50%;
}

#associates-table
  i.anticon.anticon-edit.ant-table-filter-icon.ant-dropdown-trigger {
  background-color: black;
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: rgb(237, 237, 237);
}

#associates-table
  i.anticon.anticon-search.ant-table-filter-icon.ant-table-filter-open.ant-dropdown-trigger.ant-dropdown-open {
  background-color: black;
  color: #1890ff;
}

.summary-table-container .ant-table-tbody > tr > td {
  padding: 3px;
  font-weight: bold;
}

.associates-table-container .ant-table-tbody > tr > td {
  padding: 3px;
}

.associates-table-container
  th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-align-center.ant-table-row-cell-break-word {
  padding: 8px;
}

.packages-container {
  display: flex;
  margin: 0 auto;
  padding: 24px 48px;
}

.packages-card {
  width: 45%;
  margin: 0 auto;
  border: 2px solid #ccc;
  box-shadow: 5px 6px 6px #889;
  border-radius: 3px;
}

.custom-apps-card {
  max-height: max-content;
}

.custom-apps-card .custom-apps-list {
  list-style: outside none none;
  padding-left: 0px;

  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.custom-apps-card .custom-apps-list > li {
  padding: 8px 16px;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
}

.packages-checkboxes-container {
  max-height: 550px;
  overflow-y: scroll;
}

.ant-checkbox-group-item {
  display: block;
  text-align: left;
  word-break: break-word;
  margin-top: 8px;
}

/* ----------- Non-Retina Screens ----------- */
/* @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .packages-card {
    height: 100%;
  }

  .packages-checkboxes-container {
    height: 400px;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .packages-checkboxes-container {
    height: 400px;
  }
} */

/* @media (max-height: 997) {
  .custom-apps-card {
    max-height: inherit;
  }
} */

@media (max-height: 1000px) {
  .custom-apps-card {
    max-height: inherit;
  }
  /* .packages-card {
    height: 100%;
  }

  .packages-checkboxes-container {
    height: 400px;
  } */
}

@media (max-width: 992px) {
  .packages-container {
    flex-direction: column;
  }

  .packages-card {
    margin: 3% auto;
    width: 80%;
  }

  /* .apps-library-card {
    max-height: none;
  }

  .packages-checkboxes-container {
    max-height: none;
    height: inherit;
  } */
}

.target-metrics-form {
  padding: 24px;
  background: #ffffff;
  width: 85%;
  margin: 2% auto;
  box-shadow: 2px 3px 12px #d9d9d9;
}
.target-metrics-form .ant-form-item {
  display: flex;
}
.zones-items-fields {
  max-width: fit-content;
}
.metrics-field-inputs .ant-form-item-label {
  width: 110px;
  text-align: left;
  word-break: break-word;
  line-height: 17px;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
}
.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1 1;
}

#teamTargetForm input#yellow_zone {
  width: 135px;
}

#teamTargetForm .ant-col.ant-form-item-control-wrapper,
#facilityTargetForm .ant-col.ant-form-item-control-wrapper {
  width: 170px;
  height: 40px;
}
#teamTargetForm .ant-col.ant-col-xs-24.ant-col-md-8,
#facilityTargetForm .ant-col.ant-col-xs-24.ant-col-md-8,
.targetzones {
  height: 70px;
}

.ant-table-wrapper.excludedskus-table {
  background-color: #fdfdfd;
}
.delete-skus-container {
  text-align: left;
}
.excludedskus-table td.sku-code {
  white-space: pre;
}

#help-targetmetrics,
#help-charts {
  margin: 5px;
  float: right;
}

.add-resources-form {
  padding: 24px;
  background: #ffffff;
  height: 535px;
}

.add-partners-form {
  padding: 24px;
  background: #ffffff;
  height: 735px;
}
.add-resources-form .ant-form-item-label,
.add-partners-form .ant-form-item-label {
  text-align: left;
  word-break: break-word;
  line-height: 17px;
  white-space: pre-wrap;
  display: flex;
}
.add-resources-form .ant-form-item,
.add-partners-form .ant-form-item {
  text-align: left;
}
#android-notification table th,
#android-notification table td {
  padding: 12px;
}
#android-notification table th.alt-center {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .zones-items-fields {
    width: 100%;
  }
  .targetzones {
    height: 140px;
  }
}

#conversation {
  --chat-height: 50%;
  --chat-header-height: 10%;
  --chat-info-height: 80%;
  --chat-input-height: 10%;
}

.conversation-container {
  height: var(--chat-height);
  width: 25%;
  min-width: 400px;
  max-width: 600px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: dodgerblue;
  border-radius: 10px 0px 0px 10px;
}

.conversationinfo-container {
  width: 100%;
  height: var(--chat-header-height);
  text-align: left;
  display: flex;
}

.conversation-users-menu .ant-dropdown-placement-bottomCenter .ant-dropdown {
  top: 495px !important;
}

.conversation-header-container {
  height: 100%;
  width: 70%;
}

.conversation-header-container .ant-menu {
  display: inline;
  border-bottom: none;
  background-color: dodgerblue;
  color: white;
}

.conversation-header-container .ant-menu .ant-menu-submenu {
  color: white;
  border-bottom: none;
}

.conversation-header-container .ant-page-header {
  padding: 5px 0 5px 24px;
}

.conversation-header-container .ant-page-header .ant-page-header-heading {
  width: 100%;
}

.conversation-header-container .ant-page-header-heading {
  display: flex;
  align-items: center;
}

.conversation-header-container .ant-page-header-heading-title {
  color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80%;
  white-space: nowrap;
  font-size: 17px;
}

.ant-page-header-heading-sub-title {
  padding-right: 0;
}

.conversation-contro-container {
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
}
.conversationwindow-container {
  width: 100%;
  height: var(--chat-info-height);
  background-color: #e6f7ff;
  overflow-x: auto;
}

.conversationinputbox-container {
  width: 100%;
  height: var(--chat-input-height);
  display: flex;
  background-color: #00a000;
}

.conversationheader{
    height: 100%;
    background-color: dodgerblue;
    display: flex;
    align-items: center;
    border-radius: 10px 0px 0px 0px;
}
.conversationheader .ant-page-header-heading-extra {
    position: absolute;
    top: 2px;
    right: 5px;
}
.end-of-messages {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.message-item {
  width: 100%;
  height: auto;
}

.first-message {
  margin-top: 10px;
}

.message-item-title {
  display: flex;
}

.message-item-content {
  display: flex;
  height: auto;
  width: 100%;
  margin: 2px 0 2px 0;
}

.message-item-text {
  text-align: left;
  line-height: 2;
  word-break: break-word;
  color: black;
  height: 100%;
  width: auto;
  max-width: 60%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 10px 10px 114px 2px rgba(0, 0, 0, 0.26);
}

.message-item-title-name {
  color: black;
  font-weight: bold;
}

.message-item-title-time {
  display: flex;
  font-size: x-small;
  color: grey;
  align-items: center;
}

.loading-dots span {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: rgb(107, 101, 101);
  border-radius: 50%;
  display: inline-block;
  animation-name: dots;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dots {
  50% {
    opacity: 0;
    transform: scale(0.7) translateY(5px);
  }
}

.create-messagegroup-main {
  width: 50%;
  height: 90%;
  margin: auto;
}

.create-messagegroup-card {
  width: 80%;
  margin: 100px 0 auto 0;
  border: 2px solid #ccc;
  box-shadow: 5px 6px 6px #889;
  border-radius: 3px;
}

#create-messagegroup-content {
  width: 100%;
}

#content-input-message {
  display: flex;
  justify-content: space-between;
  height: 50%;
}

#content-input-message .autocomplete-search {
  width: 70%;
  height: 100%;
}
#content-input-message .autocomplete-search-btn {
  width: 20%;
}

.empty-place-holder {
  height: 10px;
}

.label-input {
  display: flex;
  /*justify-content: space-between;*/
  width: 80%;
}

.whats-new-form .RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-size: 16px;
  padding: 15px;
  line-height: 1.5;
  font-family: "Lato", sans-serif;
  min-height: 300px;
}

.whats-new-form .RichEditor-controls {
  display: inline-block;
  margin-right: 10px;
}

.whats-new-form .RichEditor-controls button {
  user-select: none;
  margin: 0 5px;
  display: inline-block;
}

.whats-new-form .RichEditor-controls button.active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9;
}
.container-whatsnew-form {
  background-color: #ffffff;
  padding: 15px;
}

.whats-new-form .RichEditor-controls .divider {
  background: rgb(235, 236, 240);
  width: 1px;
  height: 24px;
  display: inline-block;
  margin: 0px 8px;
  vertical-align: middle;
}

.whats-new-form .RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
  min-height: inherit;
  max-height: 340px;
  overflow-y: scroll;
}

.whats-new-form .RichEditor-editor .public-DraftEditorPlaceholder-root,
.whats-new-form .RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

/**
  Version notes form
 */
.whats-new-form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0 auto;
}

/**
  Patch notes pages
*/
.whats-new-item {
  background-color: #ffffff;
  padding: 12px 4em;
  flex-direction: column;
  align-items: flex-start;
  margin: 2em;
  box-shadow: 0 24px 48px rgba(13, 21, 55, 0.04);
  border-radius: 5px;
}

div.whats-new-list .ant-list-pagination {
  margin-right: 2em;
}

.whats-new-post-preview {
  max-height: 50px;
}

.whats-new-post-preview h1 {
  font-size: 2em;
  background-color: #ffffff;
  text-align: left;
}

.whats-new-post-container,
#whats-new-editor {
  font-size: 16px;
  line-height: 1.5;
  background-color: #ffffff;
  text-align: left;
}
#whats-new-editor {
  height: 400px;
}

.whats-new-post-container h1,
#whats-new-editor h1 {
  font-size: 1.5em;
}

.whats-new-post-container h2,
#whats-new-editor h2 {
  font-size: 1.3em;
}

.whats-new-post-container *,
#whats-new-editor .RichEditor-editor * {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.editable-cell-value-wrap {
  min-height: 21px;
}
.device-info-version-button {
  padding: 0.4em 1em;
  background-color: #1890ff;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: -47px;
  right: 0;
}
#v2-devices-table td.editable-cell-devices {
  padding: 0;
}

.device-info-version-button:hover {
  color: #fff;
  background-color: #40a9ff;
}

div#card-device-table .ant-card-body {
  padding: 0;
}

td.editable-cell-devices:hover .editable-cell-value-wrap {
  border: 1px solid #a6cef3;
  border-radius: 7px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

#v2-devices-table
  thead
  th.ant-table-column-has-actions
  i.anticon.anticon-search.ant-table-filter-icon.ant-dropdown-trigger:hover,
#v2-devices-table
  thead
  th.ant-table-column-has-actions
  i.anticon.anticon-filter.ant-dropdown-trigger:hover {
  color: #1890ff;
}
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid black;
}
.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: 0;
}
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters
  .ant-table-filter-icon.ant-table-filter-open,
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters
  .anticon-filter.ant-table-filter-open {
  background-color: transparent;
  color: #1890ff;
}

#v2-devices-table td.editable-cell-devices div.editable-cell-value-wrap {
  padding-right: 0 !important;
}

#v2-devices-table thead th.ant-table-column-has-actions {
  /*  width: 8%; */
  padding-right: 0 !important;
  padding-left: 0 !important;
  min-height: 55px;
  background-color: black;
  color: whitesmoke;
}

#v2-devices-table .ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: calc(100vh - 500px);
  overflow-x: hidden;
  border: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: none;
}

div#card-device-table tr > th.find-column,
div#card-device-table tr > thead td.find-column {
  background-color: black;
  color: whitesmoke;
}

div#card-device-table tr > th.ping-column,
div#card-device-table tr > th.wifi-column,
div#card-device-table tr > th.hide-column {
  background-color: black;
  color: whitesmoke;
}
.table-row-dark {
  background-color: rgb(237, 237, 237);
}
.table-row-light {
  background-color: #ffffff;
}

td.ping-column-map {
  padding: 0 !important;
}

td.ping-column-map button {
  max-height: 28px;
  max-width: 28px;
  font-size: 15px;
}

.hiddenPingTooltip {
  display: none;
}

#v2-devices-table
  thead
  th.ant-table-column-has-actions
  i.anticon.anticon-filter.ant-dropdown-trigger,
#v2-devices-table
  thead
  th.ant-table-column-has-actions
  i.anticon.anticon-search.ant-table-filter-icon.ant-dropdown-trigger {
  height: 50%;
  background-color: transparent;
  width: 12px;
}

#v2-devices-table
  thead
  th.ant-table-column-has-actions
  div.ant-table-column-sorter-inner.ant-table-column-sorter-inner-full {
  position: absolute;
  margin-left: 0;
  bottom: 8px;
  right: 0px;
}

#v2-devices-table .ant-table-tbody > tr > td {
  padding: 3px;
}

/* hiding close button from InfoWindow on map */
.gm-style .gm-style-iw button {
  display: none;
  visibility: hidden;
  opacity: 0;
}
#map-components-container .ant-table-wrapper {
  display: inline-block;
  background-color: #ffffff;
  float: right;
  width: 100%;
}
th.status-column-map {
  text-align: center !important;
}
th.status-column-map .ant-table-column-sorter-inner-full {
  margin-left: 0 !important;
}
th.status-column-map span.ant-table-header-column {
  vertical-align: initial;
}
td.status-column-map span.ant-badge-status-text {
  margin-left: 0;
}
td.status-column-map {
  text-align: center;
}
#devices-table-map td.ant-table-selection-column,
#devices-table-map th.ant-table-selection-column {
  display: none;
}
#devices-table-map .ant-table-tbody > tr.ant-table-row-selected td {
  background: #e2f1ff;
}

#map-components-container th.more-column-map {
  width: 19%;
}
#devices-table-map thead > tr > th {
  background-color: black;
  color: whitesmoke;
}
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters:hover
  .ant-table-filter-icon:hover {
  color: #1890ff;
}
#devices-table-map
  i.anticon.anticon-edit.ant-table-filter-icon.ant-dropdown-trigger {
  background-color: black;
}
#map-components-container th.less-column-map {
  width: 31%;
}
div#devices-g-map {
  display: inline-block;
  float: left;
}

#devices-g-map {
  min-height: 562.5px;
}

#devices-table-map {
  min-height: 506px;
}
.ant-tabs-tab-active.ant-tabs-tab {
  border-top: 2px solid #1890ff;
}
button#refresh-devices {
  z-index: 1;
}
/*
* Forcing UI
*/
.gm-style .cluster img {
  left: 0px;
}
.gm-style .gm-style-iw-c {
  padding: 12px !important;
}
.gm-style-iw-d {
  padding: 0;
  overflow: unset !important;
}
.ant-tabs.tabs-device-information {
  position: absolute;
  top: 35px;
  padding-top: 1rem;
  width: 100%;
}
.ant-tabs.tabs-universaldevice-information {
  position: relative;
  top: -47px;
  padding-top: 1rem;
}
div#rows-per-page-devices {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0;
}
.tabs-device-information .ant-tabs-tab,
.tabs-universaldevice-information .ant-tabs-tab,
.ant-tabs.tabs-apps-management .ant-tabs-tab {
  height: 32px;
  padding: 5px 15px;
}
.tabs-device-information .ant-tabs-bar.ant-tabs-top-bar,
.tabs-universaldevice-information .ant-tabs-bar.ant-tabs-top-bar,
.ant-tabs.tabs-apps-management .ant-tabs-bar {
  border-bottom: none;
}
button#devices-toggle-map {
  position: absolute;
  bottom: 12px;
  margin-left: 1em;
}
div#devices-table-map .ant-table-footer {
  padding: 0;
}
button#devices-accuracy {
  position: absolute;
  left: 190px;
  top: 75px;
  z-index: 2;
  height: 40px;
}
button#show-all-devices {
  position: absolute;
  bottom: 12px;
  left: 145px;
}
div#confirm-delete-network {
  max-width: 250px;
}
div#confirm-force-delete-network {
  max-width: 320px;
}
div#wifiNetworks button.eye-button,
#networksDeviceInfo button.eye-button {
  height: 14px;
  border: none;
  line-height: 12px;
}
div#wifiNetworks .wifi-password-column p,
#networksDeviceInfo .wifi-password-column p {
  line-height: 12px;
}
div#wifiNetworks button.eye-button i,
#networksDeviceInfo button.eye-button i {
  font-size: 13px;
  line-height: 0px;
}
.wifi-networks-link {
  position: relative;
}

.wifi-networks-link button {
  position: absolute;
  bottom: 15px;
  z-index: 2;
}

button#force-delete-network {
  margin-left: 1rem;
}

button#force-delete-network,
button#force-delete-shortcut {
  background-color: #000;
  color: #e60000;
}

button#force-delete-network:hover,
button#force-delete-network:focus,
button#force-delete-shortcut:hover,
button#force-delete-shortcut:focus {
  background-color: rgba(0, 0, 0, 0.7);
  color: #e60000;
}

button.role-management:hover,
button.role-management:focus {
  background-color: #5992cf;
}

.enable-chrome-shortcut-popconfirm {
  max-width: 40vw;
}

.enable-chrome-shortcut-popconfirm p {
  margin: 0 1rem;
}

.devices-transfer-container div.ant-transfer-list-body-customize-wrapper {
  padding: 0;
}
.devices-transfer-container td span.ant-badge-status-text {
  display: none;
}

#v2-devices-table thead th.status-column::before {
  content: 'Blue = Active Grey = Inactive';
  position: absolute;
  width: 80px;
  z-index: 2;
  left: 50%;
  transform: translate(-45%, calc(-100% - 10px));
  background: #ccc;
  padding: 5px;
  font-size: 10px;
  line-height: 10px;
  color: #000;
  border-radius: 5px;
  top: 88px;
  display: none;
}
#v2-devices-table thead th.status-column::after {
  content: '';
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid #ccc;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  width: 8px;
  top: 15px;
  left: 50%;
  transform: translate(-50%, calc(100% + 5px));
  display: none;
}

#v2-devices-table thead th.status-column:hover::before,
#v2-devices-table thead th.status-column:hover::after {
  display: inline-block;
}

.apps-management-devices-footer-legend {
  align-self: center;
}

.devices-transfer-container .ant-table-content {
  min-height: 418px;
}

.devices-transfer-container .ant-badge-status {
  margin-left: 3px;
}

.devices-transfer-container .ant-pagination {
  padding-bottom: 12px;
}

.update-app-btn {
  background-color: rgb(187, 4, 187);
  color: white;
}

.update-app-btn:hover,
.update-app-btn:focus {
  background-color: rgb(219, 59, 219);
  color: white;
}

.app-upload-div {
  margin: 20px auto;
  width: 80%;
  text-align: center;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.app-upload-div p {
  color: rgb(0, 0, 0, 8.5);
}

.custom-apps-list .ant-list-item-action {
  margin-left: 0;
}

.custom-apps-list .ant-list-item {
  display: flex;
}

.wifi-device-modal-wrapper {
  overflow: auto !important;
}

@media screen and (max-width: 1380px) {
  #v2-devices-table thead th.status-column::before {
    transform: translate(-10%, calc(-100% - 10px));
  }
}

.app-icons > img {
  width: auto;
  height: 2em;
  justify-content: left;
}

.app-table-row {
  background-color: #fff;
}

/** CSS Utilities classes **/
.bold {
  font-weight: bold;
}

.alt-left {
  text-align: left;
}

.alt-center {
  text-align: center;
}

.m-1 {
  margin: 0.5rem;
}

.m-2 {
  margin: 1rem;
}
.alt-right {
  text-align: right;
}
.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-1 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.mx-2 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.ml-4 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: 1rem;
}

.m-10 {
  margin: 10%;
}

.my-10 {
  margin-top: 10%;
  margin-bottom: 10%;
}
.my-5 {
  margin-top: 5%;
  margin-bottom: 5%;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 2rem;
}
.p-1 {
  padding: 0.5rem;
}
.p-2 {
  padding: 1rem;
}
.p-4 {
  padding: 2em;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-1 {
  padding-bottom: 0.5rem;
}
.pb-2 {
  padding-bottom: 1rem;
}

.px-1 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.px-2 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.m-b-0 {
  margin-bottom: 0;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

.performance-card-container .m-b-0 {
  margin-bottom: 0;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.pl-2 {
  padding-left: 1rem;
}

.pr-auto {
  padding-right: auto;
}

.left-10 {
  left: 10px;
}
/* Text aligns */
.center {
  text-align: center;
}

/** **/
.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-85 {
  width: 85%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.bg-white {
  background-color: #fff;
}

/** Display Utilities **/
.none {
  display: none;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-block {
  display: inline-block;
}

.wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-right {
  justify-content: right;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-end {
  justify-content: flex-end;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.self-center {
  align-self: center;
}

.gap-1 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.gap-2 {
  grid-gap: 1rem;
  gap: 1rem;
}

/** Position utilities **/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.icon-with-tooltip {
  max-width: 32px;
  position: relative;
}
button.ant-btn.icon-with-tooltip {
  padding: 0 8px;
}
.link-tooltip {
  margin-right: 1rem;
  border: 1px solid #e2dede;
  line-height: 30px;
  display: inline-block;
  white-space: nowrap;
}
.link-tooltip i {
  color: #777;
}
.link-tooltip:hover i {
  color: #40a9ff;
}
.link-tooltip:hover {
  border: 1px solid #40a9ff;
  background-color: #fff;
}
span.tooltip-icons {
  display: none;
  margin-left: 0 !important;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
  background: #ccc;
  padding: 5px;
  font-size: 10px;
  line-height: 10px;
  color: #000;
  border-radius: 5px;
}
.tooltip-icons::before {
  display: none;
  content: '';
  border-top: 10px solid #ccc;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  width: 10px;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.icon-with-tooltip:hover span.tooltip-icons,
.icon-with-tooltip:hover .tooltip-icons::before {
  display: inline-block;
}
.conversation-windows-icon {
  background-color: transparent;
  border: none;
  color: #fff;
  padding: 0 5px;
}

#status-bad-device
  th.devices-column-with-tooltip.ant-table-column-has-actions
  i.anticon-search,
#status-good-device
  th.devices-column-with-tooltip.ant-table-column-has-actions
  i.anticon-search,
#v2-devices-table th.devices-column-with-tooltip i.anticon-search {
  height: 50%;
}
#status-good-device
  th.devices-column-with-tooltip.ant-table-column-has-actions
  i.anticon.anticon-info-circle,
#status-bad-device
  th.devices-column-with-tooltip.ant-table-column-has-actions
  i.anticon.anticon-info-circle,
#v2-devices-table th.devices-column-with-tooltip i.anticon.anticon-info-circle {
  position: absolute;
  right: 5px;
  bottom: 4px;
}

#status-good-device
  th.devices-column-with-tooltip.ant-table-column-has-actions
  i.anticon.anticon-info-circle
  svg,
#status-bad-device
  th.devices-column-with-tooltip.ant-table-column-has-actions
  i.anticon.anticon-info-circle
  svg,
#v2-devices-table
  th.devices-column-with-tooltip
  i.anticon.anticon-info-circle
  svg {
  height: 12px;
}

#apps_state > .ant-checkbox-group-item {
  display: block;
}

#help-devicev2 {
  order: 2;
  margin-left: 5px;
}

.batch-uploader {
  margin-bottom: 2vh;
}

.batch-errors {
  margin-top: 2vh;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #f5222d;
  max-height: 150px;
  overflow-y: scroll;
}

.checkbox-container {
  font-weight: bold;
  padding: 7.5px;
}

.web-portal-table-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  height: 57px;
  background-color: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.company-form-user-delete-icon {
  color: rgb(237, 56, 53);
  font-size: 12px;
  margin-right: 10px;
}

.company-form-user-delete-icon:hover {
  font-size: 14px;
  cursor: pointer;
}

.dense-form .ant-form-item-label {
  line-height: 1em;
}
.dense-form .ant-form-item {
  margin-bottom: 18px;
}

.disabled-text {
  color: #b3b3b3;
}

.table-tooltip-item {
  padding: 4px;
  margin: 4px;
  border-radius: 2px;
  border: solid 1px #e2e2e2;
}

/*Media queries for responsiveness*/
@media (max-width: 350px) {
  #logout-header span {
    display: none;
  }
}

/* NEW FILE FOR SCANNER SETTINGS */
.scanner-settings-form .ant-form-item-label {
  line-height: 1em;
}

.scanner-settings-form .ant-form-item {
  margin-bottom: 18px;
}

#sku-heat-map rect + text {
  font-weight: bold;
}

.incident-reports-table-column {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-height: 1.5; /* fallback */
  max-height: 4.5; /* fallback */
}
.image-modal-thumbnail {
  cursor: pointer;
  max-width: 3.5rem;
  max-height: 3.5rem;
  margin-right: 1rem;
  border-radius: 0.2rem;
  object-fit: contain;
}

.center-column-modal .ant-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  font-size: 16px;
}

.center-column-modal .ant-form {
  margin-right: 80px;
}

.center-column-modal h1 {
  font-size: 46px;
  font-weight: 600;
  margin-bottom: 20px;
}

.center-column-modal h2 {
  margin-bottom: 40px;
}

.center-column-modal span {
  font-weight: bold;
}

.center-column-modal .ant-spin-nested-loading {
  width: 1000px;
}

.worker-task-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 500px;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  position: relative;
}

.center-column-modal .ant-form-item {
  margin-bottom: 40px;
}

.center-column-modal .ant-form-item label {
  font-size: 20px;
}

div#root {
  height: 100%;
}

.App {
  text-align: center;
  height: 100vh;
}

.App-layout {
  height: 100%;
  width: 100%;
}

/* full page loader */
.full-page-loader {
  margin: auto;
  color: red;
}

.full-page-loader > .ant-spin-text {
  padding-top: 2rem;
  font-size: 1.5em;
  color: black;
  font-weight: 600;
}

.return-btn {
  position: absolute;
  left: 5px;
  font-size: 16px;
  padding-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.return-btn:focus,
.return-btn:hover {
  color: rgba(0, 0, 0, 0.85);
}

/** Conversations list classes / SideBar.js */

/* Reduce gap in the upper part of conversations list */
.ant-menu-item-group-title {
  display: none !important;
}

.conversation-item-checkbox > .ant-checkbox > .ant-checkbox-inner {
  border-radius: 50%;
}

.archived-conversations-menu .conversations-menu-actions {
  padding: 0 16px 0 32px !important;
}

.archived-conversations-menu .left-10 {
  left: 35px;
}

#users-table .td-actions a,
#users-table .td-actions button {
  margin: 0 3%;
}
.td-actions a.details {
  background-color: #cccccc;
}
#users-table .icon-with-tooltip.edit-password {
  background-color: #f59350;
  border-color: #f59350;
}
.icon-with-tooltip.role-management {
  background-color: #2678d0;
  border-color: #2678d0;
}
#users-table .icon-with-tooltip.edit-password i,
.icon-with-tooltip.role-management i {
  color: #ffffff;
}
#sessions-table .ant-table-pagination.ant-pagination {
  margin-right: 16px;
}

/**
  Resources page
*/

.resource-card {
  position: relative;
  display: flex;
  /* max-width: 500px; */
  width: 100%;
  background-color: #ffffff;
  height: 250px;
  border-radius: 5px;
  box-shadow: 0 24px 48px rgba(13, 21, 55, 0.04);
}

.reverse-resource-card {
  flex-direction: row-reverse;
}

.resource-card img {
  height: 170px;
  width: auto;
  max-width: 250px;
  margin: auto;
  border-radius: 5px 0 0 5px;
}

.resource-card-body {
  padding: 0 2em;
  text-align: center;
  width: 100%;
}

.resource-card-body .title {
  font-weight: 600;
  font-size: 20px;
  margin-top: 1em;
}

.resource-card-body .resource-link {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 16px;
  font-weight: 600;
}

.resource-card-body .partner-link {
  font-weight: bold;
  font-size: 20px;
  bottom: 2px;
  right: 8px;
  position: absolute;
}

.partner-card-no-link a {
  cursor: default;
}

.reverse-resource-card .resource-link {
  right: auto;
  left: 10px !important;
}

.partner-tag-container {
  display: flex;
  flex-flow: row wrap;
  max-width: 95%;
  margin: auto;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.partner-tag-container .partner-tag {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
  font-weight: 600;
  height: 32px;
  line-height: 30px;
  background: #e6f7ff;
  color: #1890ff;
  border-color: #91d5ff;
  padding: 0 10px;
}

/**
  Proximity alerts section
*/

#proximity-alerts-form .group-item-node {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  width: inherit;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}

/**
  Reports section
*/
.group-item-node {
  font-weight: 500;
  font-size: 16px;
  width: calc(100% - 46px);
  margin: 0;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}

.group-item-node .switch-opener {
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  border: 0;
  outline: none;
  transform: scale(0.83333333) rotate(0deg);
}

.switch-opener svg {
  transform: rotate(-90deg);
  transition: transform 0.3s;
}

.switch-opener.open svg {
  transform: rotate(0deg);
}

.group-item-child-tree {
  padding: 0 0 0 18px;
}

/** ------------------------------------------------------ */
.create-company-card {
  width: 90%;
  margin: 0 auto;
}

.create-company-card .ant-card-body {
  padding: 24px 48px;
}

.create-company-card h1 {
  font-size: 2em;
  font-weight: 700;
}

#create-company-form {
  width: 80%;
  margin: 0 auto;
}

#create-company-form .ant-form-item {
  padding: 0 20px 0 0;
}

#create-company-form .ant-form-item-label {
  text-align: right;
}

#create-company-form .ant-checkbox-group-item {
  display: block;
  text-align: left;
  word-break: break-word;
  margin-top: 8px;
}

#create-company-form .has-error #user-input button {
  background-color: #fff;
  border-color: #f5222d;
  color: #f5222d;
}

.company-logo-preview-container {
  position: relative;
}

.company-logo-preview-container .delete-icon {
  position: absolute;
  top: 5px;
  color: #ed3835;
}

/**
* Devices Information
*/
div#devices-g-map > div {
  position: relative !important;
}

.refreshModal > .ant-modal-content {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: none;
  --webkit-box-shadow: none;
}

@media (max-width: 768px) {
  .create-company-card .ant-card-body {
    padding: 24px;
  }

  #create-company-form {
    width: 80%;
  }

  #create-company-form .ant-form-item-label {
    text-align: left;
  }
}

@media (max-width: 1400px) {
  .resource-card {
    height: 200px;
  }

  .resource-card-body .title {
    font-size: 16px;
  }

  .resource-card img {
    max-height: 100px;
  }
}

@media (min-width: 1400px) {
  #create-company-form {
    width: 60%;
  }

  .reverse-resource-card {
    flex-direction: row;
  }

  .reverse-resource-card .resource-link {
    left: auto !important;
    right: 10px !important;
  }
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

