@import 'styles/Header.css';
@import 'styles/Main/Home/Home.css';
@import 'styles/Main/LogIn.css';
@import 'styles/Profile.css';
@import 'styles/Facilities.css';
@import 'styles/Main/Home/Menus/SideMenu.css';
@import 'styles/Main/Home/Menus/ProfileView.css';
@import 'styles/Main/Home/Pages/Performance/WorkersTable.css';
@import 'styles/Main/Home/Pages/Performance/PerformancePage.css';
@import 'styles/Main/Home/Pages/Performance/MetricsCard.css';
@import 'styles/Main/Home/Pages/DataChart/DataChartPage.css';
@import 'styles/Main/Home/Pages/Performance/AssociatesTable.css';
@import 'styles/Main/Home/Pages/Settings/ManagePackages/ManagePackages.css';
@import 'styles/Main/Home/Pages/Settings/TargetMetrics/TargetMatrics.css';
@import 'styles/Main/Home/Pages/Communication/Conversation/Conversation.css';
@import 'styles/Main/Home/Pages/Communication/Conversation/ConversationInfo.css';
@import 'styles/Main/Home/Pages/Communication/Conversation/ConversationWindow.css';
@import 'styles/Main/Home/Pages/Communication/CreateGroup/CreateGroupPage.css';
@import 'styles/VersionNotes.css';
@import 'styles/Main/Home/Pages/MDM/mdm.css';

@import 'styles/utilities.css';
div#root {
  height: 100%;
}

.App {
  text-align: center;
  height: 100vh;
}

.App-layout {
  height: 100%;
  width: 100%;
}

/* full page loader */
.full-page-loader {
  margin: auto;
  color: red;
}

.full-page-loader > .ant-spin-text {
  padding-top: 2rem;
  font-size: 1.5em;
  color: black;
  font-weight: 600;
}

.return-btn {
  position: absolute;
  left: 5px;
  font-size: 16px;
  padding-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.return-btn:focus,
.return-btn:hover {
  color: rgba(0, 0, 0, 0.85);
}

/** Conversations list classes / SideBar.js */

/* Reduce gap in the upper part of conversations list */
.ant-menu-item-group-title {
  display: none !important;
}

.conversation-item-checkbox > .ant-checkbox > .ant-checkbox-inner {
  border-radius: 50%;
}

.archived-conversations-menu .conversations-menu-actions {
  padding: 0 16px 0 32px !important;
}

.archived-conversations-menu .left-10 {
  left: 35px;
}

#users-table .td-actions a,
#users-table .td-actions button {
  margin: 0 3%;
}
.td-actions a.details {
  background-color: #cccccc;
}
#users-table .icon-with-tooltip.edit-password {
  background-color: #f59350;
  border-color: #f59350;
}
.icon-with-tooltip.role-management {
  background-color: #2678d0;
  border-color: #2678d0;
}
#users-table .icon-with-tooltip.edit-password i,
.icon-with-tooltip.role-management i {
  color: #ffffff;
}
#sessions-table .ant-table-pagination.ant-pagination {
  margin-right: 16px;
}

/**
  Resources page
*/

.resource-card {
  position: relative;
  display: flex;
  /* max-width: 500px; */
  width: 100%;
  background-color: #ffffff;
  height: 250px;
  border-radius: 5px;
  box-shadow: 0 24px 48px rgba(13, 21, 55, 0.04);
}

.reverse-resource-card {
  flex-direction: row-reverse;
}

.resource-card img {
  height: 170px;
  width: auto;
  max-width: 250px;
  margin: auto;
  border-radius: 5px 0 0 5px;
}

.resource-card-body {
  padding: 0 2em;
  text-align: center;
  width: 100%;
}

.resource-card-body .title {
  font-weight: 600;
  font-size: 20px;
  margin-top: 1em;
}

.resource-card-body .resource-link {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 16px;
  font-weight: 600;
}

.resource-card-body .partner-link {
  font-weight: bold;
  font-size: 20px;
  bottom: 2px;
  right: 8px;
  position: absolute;
}

.partner-card-no-link a {
  cursor: default;
}

.reverse-resource-card .resource-link {
  right: auto;
  left: 10px !important;
}

.partner-tag-container {
  display: flex;
  flex-flow: row wrap;
  max-width: 95%;
  margin: auto;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.partner-tag-container .partner-tag {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
  font-weight: 600;
  height: 32px;
  line-height: 30px;
  background: #e6f7ff;
  color: #1890ff;
  border-color: #91d5ff;
  padding: 0 10px;
}

/**
  Proximity alerts section
*/

#proximity-alerts-form .group-item-node {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  width: inherit;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}

/**
  Reports section
*/
.group-item-node {
  font-weight: 500;
  font-size: 16px;
  width: calc(100% - 46px);
  margin: 0;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}

.group-item-node .switch-opener {
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  border: 0;
  outline: none;
  transform: scale(0.83333333) rotate(0deg);
}

.switch-opener svg {
  transform: rotate(-90deg);
  transition: transform 0.3s;
}

.switch-opener.open svg {
  transform: rotate(0deg);
}

.group-item-child-tree {
  padding: 0 0 0 18px;
}

/** ------------------------------------------------------ */
.create-company-card {
  width: 90%;
  margin: 0 auto;
}

.create-company-card .ant-card-body {
  padding: 24px 48px;
}

.create-company-card h1 {
  font-size: 2em;
  font-weight: 700;
}

#create-company-form {
  width: 80%;
  margin: 0 auto;
}

#create-company-form .ant-form-item {
  padding: 0 20px 0 0;
}

#create-company-form .ant-form-item-label {
  text-align: right;
}

#create-company-form .ant-checkbox-group-item {
  display: block;
  text-align: left;
  word-break: break-word;
  margin-top: 8px;
}

#create-company-form .has-error #user-input button {
  background-color: #fff;
  border-color: #f5222d;
  color: #f5222d;
}

.company-logo-preview-container {
  position: relative;
}

.company-logo-preview-container .delete-icon {
  position: absolute;
  top: 5px;
  color: #ed3835;
}

/**
* Devices Information
*/
div#devices-g-map > div {
  position: relative !important;
}

.refreshModal > .ant-modal-content {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: none;
  --webkit-box-shadow: none;
}

@media (max-width: 768px) {
  .create-company-card .ant-card-body {
    padding: 24px;
  }

  #create-company-form {
    width: 80%;
  }

  #create-company-form .ant-form-item-label {
    text-align: left;
  }
}

@media (max-width: 1400px) {
  .resource-card {
    height: 200px;
  }

  .resource-card-body .title {
    font-size: 16px;
  }

  .resource-card img {
    max-height: 100px;
  }
}

@media (min-width: 1400px) {
  #create-company-form {
    width: 60%;
  }

  .reverse-resource-card {
    flex-direction: row;
  }

  .reverse-resource-card .resource-link {
    left: auto !important;
    right: 10px !important;
  }
}
