aside.sider-container {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  background-color: #fff;
}

.side-menus {
  height: 95%;
  width: 100%;
  background-color: white;
  text-align: left;
  overflow-x: hidden;
}
.side-menu-bar {
  height: 100%;
  background-color: #fff;
}
.side-menu-bar > .mobile-button {
  display: none;
  position: absolute;
  top: -40px;
  left: 25px;
  height: 40px;
  background-color: #ed3835;
  color: white;
  font-weight: bold;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.side-menu-bar > .mobile-button:hover {
  border: none;
  outline: none;
}

.side-menu-bar > .mobile-button:focus {
  outline: none;
}

ul.menu-sidebar-options.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
ul.menu-sidebar-options.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 27px !important;
}
.menu-sidebar-options {
  border: none;
  width: 100%;
  /* height: 100%; */
  padding-left: 5px;
  padding-bottom: 37px;
}

.avatar-sidebar-menu {
  background-color: #000;
  font-weight: bold;
}
button.collapsed-navigation {
  width: 100%;
  height: 5%;
  background-color: #ed3835;
  border: 1px solid #ed3835;
  outline: none;
  position: absolute;
  bottom: 0;
  left: 0;
}
button.collapsed-navigation i {
  color: #fff;
  font-size: 18px;
}
ul.ant-menu.menu-sidebar-options.ant-menu-inline-collapsed {
  padding-left: 0;
}
div#company-logo-container {
  width: 190px;
  height: auto;
  min-height: 90px;
  margin: 8px auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.company-logo {
  vertical-align: middle;
  align-self: center;
  border-style: none;
  max-width: 170px;
  height: auto;
  max-height: 90px;
}

div.add-company-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 150px;
  height: 80px;
  color: rgba(0, 0, 0, 0.2);
  margin: 18px auto;
  text-align: center;
  border: 2px dashed rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

div#collapsed-add-logo {
  width: 40px;
  height: 40px;
}

div.add-company-logo-container:hover,
div#collapsed-add-logo:hover {
  color: rgba(0, 0, 0, 0.65);
  border-color: rgba(0, 0, 0, 0.5);
}

div.add-company-logo-container > p {
  margin-bottom: 4px;
}

.company-logo-tooltip,
.add-logo-tooltip {
  position: absolute;
  visibility: hidden;
  z-index: 1;
  width: 105px;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 6px 0;
}

.company-logo-tooltip {
  top: 36px;
  left: 190px;
}

.add-logo-tooltip {
  top: 40px;
  left: 190px;
}

.company-logo-tooltip::before,
.add-logo-tooltip::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;

  border-top: 3.5px solid transparent;
  border-bottom: 3.5px solid transparent;
  border-right: 3.7px solid rgba(0, 0, 0, 0.75);
  left: -3.7px;

  top: 12px;
}

#company-logo-container:hover .company-logo-tooltip,
.add-company-logo-container:hover .add-logo-tooltip {
  transition-delay: 0.3s;
  visibility: visible;
}

aside.ant-layout-sider-collapsed div#company-logo-container {
  max-width: 70px;
}

aside.ant-layout-sider-collapsed
  div#company-logo-container
  > .company-logo-tooltip {
  top: 35px;
  left: 82px;
}

div#collapsed-add-logo > .add-logo-tooltip {
  top: 22px;
  left: 82px;
}

.side-submenu-title {
  font-weight: bold;
}
.menu-item-action-icon {
  display: none;
  z-index: 10;
  position: absolute;
  margin-left: -5px;
}

.ant-menu-item:hover > .menu-item-action-icon {
  display: initial;
}

.side-menu-item-icon {
  margin-left: 14px;
}

.menu-item-new-feature {
  color: rgb(237, 56, 53);
  padding: 2px 4px;
  border-radius: 4px;
  background-color: rgba(237, 56, 53, 0.1);
}
.menu-item-new-feature:hover {
  cursor: pointer;
}
#fav-key-container {
  z-index: 11;
  padding: 4px 4px 4px 8px;
  text-align: left;
  width: 100%;
  background: rgb(255, 255, 255);
  position: absolute;
  left: 0;
  bottom: 5%;
}

@media (max-width: 992px) {
  .sider-container {
    position: absolute;
    z-index: 1;
    height: 100%;
  }

  .side-menu-bar > .mobile-button {
    display: block;
  }
}
