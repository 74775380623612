.packages-container {
  display: flex;
  margin: 0 auto;
  padding: 24px 48px;
}

.packages-card {
  width: 45%;
  margin: 0 auto;
  border: 2px solid #ccc;
  box-shadow: 5px 6px 6px #889;
  border-radius: 3px;
}

.custom-apps-card {
  max-height: max-content;
}

.custom-apps-card .custom-apps-list {
  list-style: outside none none;
  padding-left: 0px;

  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.custom-apps-card .custom-apps-list > li {
  padding: 8px 16px;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
}

.packages-checkboxes-container {
  max-height: 550px;
  overflow-y: scroll;
}

.ant-checkbox-group-item {
  display: block;
  text-align: left;
  word-break: break-word;
  margin-top: 8px;
}

/* ----------- Non-Retina Screens ----------- */
/* @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .packages-card {
    height: 100%;
  }

  .packages-checkboxes-container {
    height: 400px;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .packages-checkboxes-container {
    height: 400px;
  }
} */

/* @media (max-height: 997) {
  .custom-apps-card {
    max-height: inherit;
  }
} */

@media (max-height: 1000px) {
  .custom-apps-card {
    max-height: inherit;
  }
  /* .packages-card {
    height: 100%;
  }

  .packages-checkboxes-container {
    height: 400px;
  } */
}

@media (max-width: 992px) {
  .packages-container {
    flex-direction: column;
  }

  .packages-card {
    margin: 3% auto;
    width: 80%;
  }

  /* .apps-library-card {
    max-height: none;
  }

  .packages-checkboxes-container {
    max-height: none;
    height: inherit;
  } */
}
