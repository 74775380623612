.performance-page-wrapper {
  height: 100%;
  width: 100%;
}

.performance-page-row {
  height: 100%;
  width: 100%;
}

.performance-page-col {
  height: 100%;
  display: flex;
  /*  justify-content: center;
  align-items: center; */
}
.performance-single-day-picker .ant-calendar-time-picker-btn,
.performance-range-date-picker .ant-calendar-ok-btn {
  display: none !important;
}
.performance-single-day-picker .ant-calendar-ok-btn {
  visibility: hidden;
}

div#performance-settings-container div.ant-col {
  height: 100%;
}
div#performance-settings-container {
  background-color: #fff;
  margin: 2.5px;
  padding: 10px;
  width: 99.5%;
}
#refresh-rate-selector {
  display: block;
  margin: 0.5rem 0;
}
#help-performance {
  max-width: 26px !important;
  min-width: 26px !important;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
}
#performance-settings-container span.past-averages {
  width: 100%;
}
#get-performance-metrics-button {
  /* padding: 0 5px;  WH-801 Committing out to make room for loading spinner on Get Metrics button*/
  margin-left: 2px;
}
ul.slick-dots.custom-dots-slider {
  top: -2px;
  height: 30px;
}
@media screen and (max-width: 1380px) {
  #get-performance-metrics-button {
    margin-top: 0.5rem;
    margin-left: 0;
  }
}
/*
span#last-updated-performance {
  width: max-content;
  position: absolute;
  top: 5px;
  margin-left: 10px;
} */
.slider-buttons {
  height: 10px;
  width: 10px;
  padding: 0;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 50%;
  border-color: gray;
}
.slider-buttons:hover {
  background-color: #ef9593;
}
.dot-red {
  background-color: #ed3835;
}
.dot-grey {
  background-color: #f1f1f1;
}
