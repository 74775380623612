/** CSS Utilities classes **/
.bold {
  font-weight: bold;
}

.alt-left {
  text-align: left;
}

.alt-center {
  text-align: center;
}

.m-1 {
  margin: 0.5rem;
}

.m-2 {
  margin: 1rem;
}
.alt-right {
  text-align: right;
}
.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-1 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.mx-2 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.ml-4 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: 1rem;
}

.m-10 {
  margin: 10%;
}

.my-10 {
  margin-top: 10%;
  margin-bottom: 10%;
}
.my-5 {
  margin-top: 5%;
  margin-bottom: 5%;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 2rem;
}
.p-1 {
  padding: 0.5rem;
}
.p-2 {
  padding: 1rem;
}
.p-4 {
  padding: 2em;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-1 {
  padding-bottom: 0.5rem;
}
.pb-2 {
  padding-bottom: 1rem;
}

.px-1 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.px-2 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.m-b-0 {
  margin-bottom: 0;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

.performance-card-container .m-b-0 {
  margin-bottom: 0;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.pl-2 {
  padding-left: 1rem;
}

.pr-auto {
  padding-right: auto;
}

.left-10 {
  left: 10px;
}
/* Text aligns */
.center {
  text-align: center;
}

/** **/
.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-85 {
  width: 85%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.bg-white {
  background-color: #fff;
}

/** Display Utilities **/
.none {
  display: none;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-block {
  display: inline-block;
}

.wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-right {
  justify-content: right;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-end {
  justify-content: flex-end;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.self-center {
  align-self: center;
}

.gap-1 {
  gap: 0.5rem;
}
.gap-2 {
  gap: 1rem;
}

/** Position utilities **/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.icon-with-tooltip {
  max-width: 32px;
  position: relative;
}
button.ant-btn.icon-with-tooltip {
  padding: 0 8px;
}
.link-tooltip {
  margin-right: 1rem;
  border: 1px solid #e2dede;
  line-height: 30px;
  display: inline-block;
  white-space: nowrap;
}
.link-tooltip i {
  color: #777;
}
.link-tooltip:hover i {
  color: #40a9ff;
}
.link-tooltip:hover {
  border: 1px solid #40a9ff;
  background-color: #fff;
}
span.tooltip-icons {
  display: none;
  margin-left: 0 !important;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
  background: #ccc;
  padding: 5px;
  font-size: 10px;
  line-height: 10px;
  color: #000;
  border-radius: 5px;
}
.tooltip-icons::before {
  display: none;
  content: '';
  border-top: 10px solid #ccc;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  width: 10px;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.icon-with-tooltip:hover span.tooltip-icons,
.icon-with-tooltip:hover .tooltip-icons::before {
  display: inline-block;
}
.conversation-windows-icon {
  background-color: transparent;
  border: none;
  color: #fff;
  padding: 0 5px;
}

#status-bad-device
  th.devices-column-with-tooltip.ant-table-column-has-actions
  i.anticon-search,
#status-good-device
  th.devices-column-with-tooltip.ant-table-column-has-actions
  i.anticon-search,
#v2-devices-table th.devices-column-with-tooltip i.anticon-search {
  height: 50%;
}
#status-good-device
  th.devices-column-with-tooltip.ant-table-column-has-actions
  i.anticon.anticon-info-circle,
#status-bad-device
  th.devices-column-with-tooltip.ant-table-column-has-actions
  i.anticon.anticon-info-circle,
#v2-devices-table th.devices-column-with-tooltip i.anticon.anticon-info-circle {
  position: absolute;
  right: 5px;
  bottom: 4px;
}

#status-good-device
  th.devices-column-with-tooltip.ant-table-column-has-actions
  i.anticon.anticon-info-circle
  svg,
#status-bad-device
  th.devices-column-with-tooltip.ant-table-column-has-actions
  i.anticon.anticon-info-circle
  svg,
#v2-devices-table
  th.devices-column-with-tooltip
  i.anticon.anticon-info-circle
  svg {
  height: 12px;
}

#apps_state > .ant-checkbox-group-item {
  display: block;
}

#help-devicev2 {
  order: 2;
  margin-left: 5px;
}

.batch-uploader {
  margin-bottom: 2vh;
}

.batch-errors {
  margin-top: 2vh;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #f5222d;
  max-height: 150px;
  overflow-y: scroll;
}

.checkbox-container {
  font-weight: bold;
  padding: 7.5px;
}

.web-portal-table-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  height: 57px;
  background-color: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.company-form-user-delete-icon {
  color: rgb(237, 56, 53);
  font-size: 12px;
  margin-right: 10px;
}

.company-form-user-delete-icon:hover {
  font-size: 14px;
  cursor: pointer;
}

.dense-form .ant-form-item-label {
  line-height: 1em;
}
.dense-form .ant-form-item {
  margin-bottom: 18px;
}

.disabled-text {
  color: #b3b3b3;
}

.table-tooltip-item {
  padding: 4px;
  margin: 4px;
  border-radius: 2px;
  border: solid 1px #e2e2e2;
}

/*Media queries for responsiveness*/
@media (max-width: 350px) {
  #logout-header span {
    display: none;
  }
}

/* NEW FILE FOR SCANNER SETTINGS */
.scanner-settings-form .ant-form-item-label {
  line-height: 1em;
}

.scanner-settings-form .ant-form-item {
  margin-bottom: 18px;
}

#sku-heat-map rect + text {
  font-weight: bold;
}

.incident-reports-table-column {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-height: 1.5; /* fallback */
  max-height: 4.5; /* fallback */
}
.image-modal-thumbnail {
  cursor: pointer;
  max-width: 3.5rem;
  max-height: 3.5rem;
  margin-right: 1rem;
  border-radius: 0.2rem;
  object-fit: contain;
}

.center-column-modal .ant-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  font-size: 16px;
}

.center-column-modal .ant-form {
  margin-right: 80px;
}

.center-column-modal h1 {
  font-size: 46px;
  font-weight: 600;
  margin-bottom: 20px;
}

.center-column-modal h2 {
  margin-bottom: 40px;
}

.center-column-modal span {
  font-weight: bold;
}

.center-column-modal .ant-spin-nested-loading {
  width: 1000px;
}

.worker-task-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 500px;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  position: relative;
}

.center-column-modal .ant-form-item {
  margin-bottom: 40px;
}

.center-column-modal .ant-form-item label {
  font-size: 20px;
}
