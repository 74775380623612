#conversation {
  --chat-height: 50%;
  --chat-header-height: 10%;
  --chat-info-height: 80%;
  --chat-input-height: 10%;
}

.conversation-container {
  height: var(--chat-height);
  width: 25%;
  min-width: 400px;
  max-width: 600px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: dodgerblue;
  border-radius: 10px 0px 0px 10px;
}

.conversationinfo-container {
  width: 100%;
  height: var(--chat-header-height);
  text-align: left;
  display: flex;
}

.conversation-users-menu .ant-dropdown-placement-bottomCenter .ant-dropdown {
  top: 495px !important;
}

.conversation-header-container {
  height: 100%;
  width: 70%;
}

.conversation-header-container .ant-menu {
  display: inline;
  border-bottom: none;
  background-color: dodgerblue;
  color: white;
}

.conversation-header-container .ant-menu .ant-menu-submenu {
  color: white;
  border-bottom: none;
}

.conversation-header-container .ant-page-header {
  padding: 5px 0 5px 24px;
}

.conversation-header-container .ant-page-header .ant-page-header-heading {
  width: 100%;
}

.conversation-header-container .ant-page-header-heading {
  display: flex;
  align-items: center;
}

.conversation-header-container .ant-page-header-heading-title {
  color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80%;
  white-space: nowrap;
  font-size: 17px;
}

.ant-page-header-heading-sub-title {
  padding-right: 0;
}

.conversation-contro-container {
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
}
.conversationwindow-container {
  width: 100%;
  height: var(--chat-info-height);
  background-color: #e6f7ff;
  overflow-x: auto;
}

.conversationinputbox-container {
  width: 100%;
  height: var(--chat-input-height);
  display: flex;
  background-color: #00a000;
}
