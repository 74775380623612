.editable-cell-value-wrap {
  min-height: 21px;
}
.device-info-version-button {
  padding: 0.4em 1em;
  background-color: #1890ff;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: -47px;
  right: 0;
}
#v2-devices-table td.editable-cell-devices {
  padding: 0;
}

.device-info-version-button:hover {
  color: #fff;
  background-color: #40a9ff;
}

div#card-device-table .ant-card-body {
  padding: 0;
}

td.editable-cell-devices:hover .editable-cell-value-wrap {
  border: 1px solid #a6cef3;
  border-radius: 7px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

#v2-devices-table
  thead
  th.ant-table-column-has-actions
  i.anticon.anticon-search.ant-table-filter-icon.ant-dropdown-trigger:hover,
#v2-devices-table
  thead
  th.ant-table-column-has-actions
  i.anticon.anticon-filter.ant-dropdown-trigger:hover {
  color: #1890ff;
}
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid black;
}
.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: 0;
}
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters
  .ant-table-filter-icon.ant-table-filter-open,
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters
  .anticon-filter.ant-table-filter-open {
  background-color: transparent;
  color: #1890ff;
}

#v2-devices-table td.editable-cell-devices div.editable-cell-value-wrap {
  padding-right: 0 !important;
}

#v2-devices-table thead th.ant-table-column-has-actions {
  /*  width: 8%; */
  padding-right: 0 !important;
  padding-left: 0 !important;
  min-height: 55px;
  background-color: black;
  color: whitesmoke;
}

#v2-devices-table .ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: calc(100vh - 500px);
  overflow-x: hidden;
  border: 0;
  border-radius: 4px 4px 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

div#card-device-table tr > th.find-column,
div#card-device-table tr > thead td.find-column {
  background-color: black;
  color: whitesmoke;
}

div#card-device-table tr > th.ping-column,
div#card-device-table tr > th.wifi-column,
div#card-device-table tr > th.hide-column {
  background-color: black;
  color: whitesmoke;
}
.table-row-dark {
  background-color: rgb(237, 237, 237);
}
.table-row-light {
  background-color: #ffffff;
}

td.ping-column-map {
  padding: 0 !important;
}

td.ping-column-map button {
  max-height: 28px;
  max-width: 28px;
  font-size: 15px;
}

.hiddenPingTooltip {
  display: none;
}

#v2-devices-table
  thead
  th.ant-table-column-has-actions
  i.anticon.anticon-filter.ant-dropdown-trigger,
#v2-devices-table
  thead
  th.ant-table-column-has-actions
  i.anticon.anticon-search.ant-table-filter-icon.ant-dropdown-trigger {
  height: 50%;
  background-color: transparent;
  width: 12px;
}

#v2-devices-table
  thead
  th.ant-table-column-has-actions
  div.ant-table-column-sorter-inner.ant-table-column-sorter-inner-full {
  position: absolute;
  margin-left: 0;
  bottom: 8px;
  right: 0px;
}

#v2-devices-table .ant-table-tbody > tr > td {
  padding: 3px;
}

/* hiding close button from InfoWindow on map */
.gm-style .gm-style-iw button {
  display: none;
  visibility: hidden;
  opacity: 0;
}
#map-components-container .ant-table-wrapper {
  display: inline-block;
  background-color: #ffffff;
  float: right;
  width: 100%;
}
th.status-column-map {
  text-align: center !important;
}
th.status-column-map .ant-table-column-sorter-inner-full {
  margin-left: 0 !important;
}
th.status-column-map span.ant-table-header-column {
  vertical-align: initial;
}
td.status-column-map span.ant-badge-status-text {
  margin-left: 0;
}
td.status-column-map {
  text-align: center;
}
#devices-table-map td.ant-table-selection-column,
#devices-table-map th.ant-table-selection-column {
  display: none;
}
#devices-table-map .ant-table-tbody > tr.ant-table-row-selected td {
  background: #e2f1ff;
}

#map-components-container th.more-column-map {
  width: 19%;
}
#devices-table-map thead > tr > th {
  background-color: black;
  color: whitesmoke;
}
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters:hover
  .ant-table-filter-icon:hover {
  color: #1890ff;
}
#devices-table-map
  i.anticon.anticon-edit.ant-table-filter-icon.ant-dropdown-trigger {
  background-color: black;
}
#map-components-container th.less-column-map {
  width: 31%;
}
div#devices-g-map {
  display: inline-block;
  float: left;
}

#devices-g-map {
  min-height: 562.5px;
}

#devices-table-map {
  min-height: 506px;
}
.ant-tabs-tab-active.ant-tabs-tab {
  border-top: 2px solid #1890ff;
}
button#refresh-devices {
  z-index: 1;
}
/*
* Forcing UI
*/
.gm-style .cluster img {
  left: 0px;
}
.gm-style .gm-style-iw-c {
  padding: 12px !important;
}
.gm-style-iw-d {
  padding: 0;
  overflow: unset !important;
}
.ant-tabs.tabs-device-information {
  position: absolute;
  top: 35px;
  padding-top: 1rem;
  width: 100%;
}
.ant-tabs.tabs-universaldevice-information {
  position: relative;
  top: -47px;
  padding-top: 1rem;
}
div#rows-per-page-devices {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0;
}
.tabs-device-information .ant-tabs-tab,
.tabs-universaldevice-information .ant-tabs-tab,
.ant-tabs.tabs-apps-management .ant-tabs-tab {
  height: 32px;
  padding: 5px 15px;
}
.tabs-device-information .ant-tabs-bar.ant-tabs-top-bar,
.tabs-universaldevice-information .ant-tabs-bar.ant-tabs-top-bar,
.ant-tabs.tabs-apps-management .ant-tabs-bar {
  border-bottom: none;
}
button#devices-toggle-map {
  position: absolute;
  bottom: 12px;
  margin-left: 1em;
}
div#devices-table-map .ant-table-footer {
  padding: 0;
}
button#devices-accuracy {
  position: absolute;
  left: 190px;
  top: 75px;
  z-index: 2;
  height: 40px;
}
button#show-all-devices {
  position: absolute;
  bottom: 12px;
  left: 145px;
}
div#confirm-delete-network {
  max-width: 250px;
}
div#confirm-force-delete-network {
  max-width: 320px;
}
div#wifiNetworks button.eye-button,
#networksDeviceInfo button.eye-button {
  height: 14px;
  border: none;
  line-height: 12px;
}
div#wifiNetworks .wifi-password-column p,
#networksDeviceInfo .wifi-password-column p {
  line-height: 12px;
}
div#wifiNetworks button.eye-button i,
#networksDeviceInfo button.eye-button i {
  font-size: 13px;
  line-height: 0px;
}
.wifi-networks-link {
  position: relative;
}

.wifi-networks-link button {
  position: absolute;
  bottom: 15px;
  z-index: 2;
}

button#force-delete-network {
  margin-left: 1rem;
}

button#force-delete-network,
button#force-delete-shortcut {
  background-color: #000;
  color: #e60000;
}

button#force-delete-network:hover,
button#force-delete-network:focus,
button#force-delete-shortcut:hover,
button#force-delete-shortcut:focus {
  background-color: rgba(0, 0, 0, 0.7);
  color: #e60000;
}

button.role-management:hover,
button.role-management:focus {
  background-color: #5992cf;
}

.enable-chrome-shortcut-popconfirm {
  max-width: 40vw;
}

.enable-chrome-shortcut-popconfirm p {
  margin: 0 1rem;
}

.devices-transfer-container div.ant-transfer-list-body-customize-wrapper {
  padding: 0;
}
.devices-transfer-container td span.ant-badge-status-text {
  display: none;
}

#v2-devices-table thead th.status-column::before {
  content: 'Blue = Active Grey = Inactive';
  position: absolute;
  width: 80px;
  z-index: 2;
  left: 50%;
  transform: translate(-45%, calc(-100% - 10px));
  background: #ccc;
  padding: 5px;
  font-size: 10px;
  line-height: 10px;
  color: #000;
  border-radius: 5px;
  top: 88px;
  display: none;
}
#v2-devices-table thead th.status-column::after {
  content: '';
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid #ccc;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  width: 8px;
  top: 15px;
  left: 50%;
  transform: translate(-50%, calc(100% + 5px));
  display: none;
}

#v2-devices-table thead th.status-column:hover::before,
#v2-devices-table thead th.status-column:hover::after {
  display: inline-block;
}

.apps-management-devices-footer-legend {
  align-self: center;
}

.devices-transfer-container .ant-table-content {
  min-height: 418px;
}

.devices-transfer-container .ant-badge-status {
  margin-left: 3px;
}

.devices-transfer-container .ant-pagination {
  padding-bottom: 12px;
}

.update-app-btn {
  background-color: rgb(187, 4, 187);
  color: white;
}

.update-app-btn:hover,
.update-app-btn:focus {
  background-color: rgb(219, 59, 219);
  color: white;
}

.app-upload-div {
  margin: 20px auto;
  width: 80%;
  text-align: center;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.app-upload-div p {
  color: rgb(0, 0, 0, 8.5);
}

.custom-apps-list .ant-list-item-action {
  margin-left: 0;
}

.custom-apps-list .ant-list-item {
  display: flex;
}

.wifi-device-modal-wrapper {
  overflow: auto !important;
}

@media screen and (max-width: 1380px) {
  #v2-devices-table thead th.status-column::before {
    transform: translate(-10%, calc(-100% - 10px));
  }
}

.app-icons > img {
  width: auto;
  height: 2em;
  justify-content: left;
}

.app-table-row {
  background-color: #fff;
}
