.workers-table-wrapper {
  height: auto;
  width: 100% !important;
  flex: 0 0 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  background-color: #fff;
}
.workers-table-wrapper.ant-layout-sider-collapsed {
  height: auto;
  width: 0 !important;
  flex: 0 0 0 !important;
  max-width: 0 !important;
  min-width: 0 !important;
}

.workers-table-wrapper.ant-layout-sider-collapsed div.ant-card-head,
.workers-table-wrapper.ant-layout-sider-collapsed div.ant-card-body {
  padding: 0;
}
.workers-table-wrapper .ant-table-row-cell-break-word .ant-table-column-title {
  word-break: initial;
}
.workers-table-container {
  overflow-x: hidden;
}
.workers-table-container tr.ant-table-row-selected td,
.workers-table-container tr.ant-table-row-selected > td.ant-table-column-sort {
  background-color: #e6f7ff;
}
.workers-table-container th.ant-table-selection-column,
.workers-table-container td.ant-table-selection-column {
  display: none;
}
.workers-table-container .ant-table-row-level-0 td:nth-child(2) {
  width: 140px;
}
.workers-card-container {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
}

.workers-card-title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.workers-card-header-and-shortcut {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.workers-card-title-wrapper p {
  font-size: large;
  color: #565656;
  font-weight: bold;
  margin: 0;
}

.table-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-radius: 10px;
}

div.table-wrapper::-webkit-scrollbar {
  width: 17px;
}

button.collapse-associates-performance {
  background-color: #000;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 2px;
  position: relative;
}

button.collapse-associates-performance:focus {
  outline: none;
}

button.collapse-associates-performance i {
  color: #fff;
  font-size: 18px;
  background-color: #ed3835;
  position: absolute;
  left: -12px;
  padding: 5px;
  z-index: 2;
  border-radius: 50%;
}

#performance-all-associates {
  background-color: #ed3835;
  padding: 5px 10px;
  color: #fff;
  height: auto;
  font-weight: bold;
  border: none;
  width: min-content;
}

#associates-sidebar-shortcut-table {
  background-color: #fff;
  color: #ed3835;
  border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
}

#associates-sidebar-shortcut-table:hover {
  background-color: #e61714;
  color: #fff;
}

.workers-card-title-wrapper button#performance-all-associates {
  border-radius: 0 !important;
}
.popup-associates-data {
  padding: 5px 10px;
}
.popup-associates-data .ant-radio-wrapper {
  display: block;
}
/* .workers-table-container th.associates-data-title {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
} */
td.red-zone-metric,
.red-zone-metric .ant-statistic-content-value,
.red-zone-metric .ant-statistic-content-prefix {
  color: #e61714 !important;
}
td.green-zone-metric,
.green-zone-metric .ant-statistic-content-value,
.green-zone-metric .ant-statistic-content-prefix {
  color: #4fa800 !important;
}
td.yellow-zone-metric,
.yellow-zone-metric .ant-statistic-content-value,
.yellow-zone-metric .ant-statistic-content-prefix {
  color: #ef9b0f !important;
}

td.associates-list-cell {
  font-weight: bold;
}

td.no-colored-zone {
  position: relative;
}
td.no-colored-zone:before {
  content: 'Please set target metrics under settings';
  position: absolute;
  font-size: 9px;
  top: -25px;
  background-color: #ccc;
  border-radius: 5px;
  padding: 2px;
  color: #000;
  right: 10px;
  z-index: 5;
  display: none;
}

td.no-colored-zone:hover:before {
  display: block;
}
div.workers-table-container tbody td.associates-data-title {
  word-break: break-all;
}
div.workers-table-container thead .ant-table-header-column,
div.workers-table-container thead .ant-table-column-sorters {
  width: 95%;
}
thead
  th.associates-data-title
  i.anticon.anticon-edit.ant-table-filter-icon.ant-dropdown-trigger:hover,
thead
  th.associates-data-title
  i.anticon.anticon-search.ant-table-filter-icon.ant-dropdown-trigger:hover {
  background-color: transparent;
}
thead
  th.associates-data-title
  i.anticon.anticon-edit.ant-table-filter-icon.ant-dropdown-trigger,
thead
  th.associates-data-title
  i.anticon.anticon-search.ant-table-filter-icon.ant-dropdown-trigger {
  height: 50%;
  background-color: transparent;
}
div.workers-table-container
  thead
  th.associates-data-title.ant-table-column-has-actions {
  width: 25%;
  padding-right: 0 !important;
  min-height: 45px;
}
thead
  th.associates-data-title
  div.ant-table-column-sorter-inner.ant-table-column-sorter-inner-full {
  top: 10px;
  position: relative;
  margin-left: 0;
  right: 5px;
}

@media (max-width: 1200px) {
  button.collapse-associates-performance {
    display: none;
  }
  .ant-col.performance-page-col.collapsedAssociates {
    width: 100%;
  }
  .workers-table-wrapper.ant-layout-sider-collapsed {
    height: auto;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
@media screen and (max-width: 1440px) {
  thead
    th.associates-data-title
    div.ant-table-column-sorter-inner.ant-table-column-sorter-inner-full {
    right: 0;
  }
}
@media screen and (min-width: 1460px) {
  div.workers-table-container thead .ant-table-header-column,
  div.workers-table-container thead .ant-table-column-sorters {
    width: 100%;
  }
}
