.target-metrics-form {
  padding: 24px;
  background: #ffffff;
  width: 85%;
  margin: 2% auto;
  box-shadow: 2px 3px 12px #d9d9d9;
}
.target-metrics-form .ant-form-item {
  display: flex;
}
.zones-items-fields {
  max-width: fit-content;
}
.metrics-field-inputs .ant-form-item-label {
  width: 110px;
  text-align: left;
  word-break: break-word;
  line-height: 17px;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
}
.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#teamTargetForm input#yellow_zone {
  width: 135px;
}

#teamTargetForm .ant-col.ant-form-item-control-wrapper,
#facilityTargetForm .ant-col.ant-form-item-control-wrapper {
  width: 170px;
  height: 40px;
}
#teamTargetForm .ant-col.ant-col-xs-24.ant-col-md-8,
#facilityTargetForm .ant-col.ant-col-xs-24.ant-col-md-8,
.targetzones {
  height: 70px;
}

.ant-table-wrapper.excludedskus-table {
  background-color: #fdfdfd;
}
.delete-skus-container {
  text-align: left;
}
.excludedskus-table td.sku-code {
  white-space: pre;
}

#help-targetmetrics,
#help-charts {
  margin: 5px;
  float: right;
}

.add-resources-form {
  padding: 24px;
  background: #ffffff;
  height: 535px;
}

.add-partners-form {
  padding: 24px;
  background: #ffffff;
  height: 735px;
}
.add-resources-form .ant-form-item-label,
.add-partners-form .ant-form-item-label {
  text-align: left;
  word-break: break-word;
  line-height: 17px;
  white-space: pre-wrap;
  display: flex;
}
.add-resources-form .ant-form-item,
.add-partners-form .ant-form-item {
  text-align: left;
}
#android-notification table th,
#android-notification table td {
  padding: 12px;
}
#android-notification table th.alt-center {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .zones-items-fields {
    width: 100%;
  }
  .targetzones {
    height: 140px;
  }
}
