.facilities-card,
.management-card,
.companies-card {
  margin: 3% auto;
  width: 80%;
}

.facilities-card > .ant-card-body,
.management-card > .ant-card-body,
.companies-card > .ant-card-body {
  padding: 0;
  padding-bottom: 20px;
}

.facilities-table .ant-table-pagination.ant-pagination {
  margin-right: 16px;
}

.teams-table .ant-table-pagination.ant-pagination {
  margin-right: 16px;
}

.group-type-buttons-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.group-type-name-container {
  align-self: center;
  position: absolute;
  left: 50%;
  right: 50%;
}

.management-print-button {
  margin-left: 1rem;
}

.title-and-buttons-container-users-page {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 0 24px;
}

.users-container-users-page {
  align-self: center;
  position: absolute;
  left: 50%;
  right: 50%;
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0, 0.85);
}

.facilities-card-users {
  width: 80%;
  margin: 3% auto;
}

.facilities-card-users > .ant-card-body {
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
}

span.ant-badge-status-dot.ant-badge-status-yellow,
span.ant-badge-status-dot.ant-badge-status-red,
span.ant-badge-status-dot.ant-badge-status-green {
  border-radius: 0;
}

.filter-settings-footer {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  border-top: 1px solid #e8e8e8;
}

.transparent-button {
  background-color: transparent;
  border: none;
  color: #1890ff;
  box-shadow: none;
}

#facilities-table,
#teams-table,
#users-table,
.facilities-card .ant-spin-nested-loading,
.facilities-card-users .ant-spin-nested-loading,
.ant-table-wrapper.facilities-table,
.ant-table-wrapper.teams-table,
.ant-table-wrapper.users-table {
  height: 620px;
}
.loading-table-height {
  height: 656px;
}

.ant-modal-body form#CreateUserForm,
.ant-modal-body form#CreateTeamForm {
  max-height: 400px;
  overflow-y: scroll;
}
.ant-modal.CreateTeamModal {
  padding-bottom: 0;
}
form#CreateUserForm::-webkit-scrollbar-thumb,
.ant-modal-body form#CreateTeamForm::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  width: 10px !important;
}
form#CreateUserForm::-webkit-scrollbar,
.ant-modal-body form#CreateTeamForm::-webkit-scrollbar {
  width: 10px !important;
  background-color: #efefef;
}
@media (max-width: 1200px) {
  .td-actions > button,
  .td-actions > a {
    width: 80%;
    margin: 1rem 0 !important;
  }
}

@media (max-width: 768px) {
  .facilities-table thead {
    display: none;
  }

  .facilities-table tr {
    display: flex;
    flex-direction: column;
  }

  .facilities-table td {
    padding-left: 50%;
  }

  .facilities-table td:nth-of-type(1):before {
    content: 'Name: ';
  }
  .facilities-table td:nth-of-type(2):before {
    content: 'Enable ';
  }
  .facilities-table td:nth-of-type(3):before {
    content: 'Actions ';
  }

  .td-actions > button {
    width: 100%;
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 1600px) {
  .ant-modal-body form#CreateTeamForm,
  .ant-modal-body form#CreateUserForm {
    max-height: none;
    overflow-y: auto;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1820px) {
  .ant-modal-body form#CreateUserForm {
    max-height: 600px;
    overflow-y: auto;
  }
}
@media screen and (min-width: 1821px) {
  .ant-modal-body form#CreateUserForm {
    max-height: 700px;
    overflow-y: auto;
  }
}
