.home-layout {
  height: calc(100% - 40px);
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden !important;
}

.universal-dashboard-home-link {
  display: flex;
  margin: 5px 20px;
  max-width: 220px;
}

.universal-dashboard-home-link .back-icon {
  margin: 4px;
}

.universal-metrics-container {
  min-height: 100%;
  /*  display: flex;
  justify-content: center;
  flex-direction: column; */
}
.demo-containers {
  box-shadow: #ccc 5px 3px 10px;
  background-color: #fff;
}

.super-metrics-container #movable-supercards {
  justify-content: center;
}

#movable-supercards .demo-containers {
  width: 29%;
  margin: 20px 2%;
  cursor: grabbing;
}
#fixed-supercards .demo-containers {
  width: 30%;
  margin: 20px 2%;
}
#movable-supercards .demo-containers:hover {
  cursor: grab;
}
.demo-containers h1,
.demo-number {
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 0;
}
p.demo-text {
  font-size: 1.5rem;
}
.demo-containers h1.green-metric {
  color: #22a89f;
}
.universal-options-container {
  display: flex;
  margin: 1em 2.5%;
}
span.note-intro-back {
  color: #aaa;
  font-size: 11px;
}

.universal-options-container {
  display: flex;
  justify-content: space-between;
}

.universal-options-container > .controls {
  text-align: left;
  display: flex;
}

.controls > div,
.controls > button {
  margin-right: 10px;
}

.universal-options-container > .company-select {
  display: flex;
  flex-direction: row;
}

.universal-options-container > .extra {
  display: flex;
  justify-content: end;
  align-items: center;
}

.universal-options-container > .extra > span,
.universal-options-container > .extra > div {
  margin-left: 2vw;
}

.active-role {
  padding-left: 10px;
}

.active-roles-tooltip {
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-width: 180px;
}

.small-metric {
  font-size: 16px;
  font-weight: 900;
}

.pickers-container {
  width: 100%;
  margin: 0 2.5%;
  text-align: left;
  min-height: 33px;
}
.chart-universal-metrics {
  min-height: 600px;
  width: 95%;
  margin: auto;
}
.chart-universal-metrics .ant-spin-nested-loading,
.chart-universal-metrics .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}

.chart-universal-metrics h1 {
  font-size: 24px;
  margin-bottom: 0;
}
div#universal-metrics-area-display {
  position: relative;
}
#universal-charts-options {
  display: inline-block;
  position: absolute;
  right: 0;
  top: -95px;
  margin-right: 2.5%;
  margin-top: 1rem;
}
.select-metrics-universal-charts {
  width: 200px;
}
.select-metrics-universal-charts .ant-select-selection {
  width: 200px;
}
.universal-range-picker .ant-calendar-ok-btn {
  display: none;
}
@media (max-width: 1200px) {
  .controls > div {
    margin-right: 1em;
  }

  .small-metric {
    max-width: 100px;
    word-break: break-word;
  }
}

@media (max-width: 992px) {
  #movable-supercards .demo-containers,
  #fixed-supercards .demo-containers {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .universal-options-container {
    flex-direction: column;
    width: inherit;
  }

  .universal-options-container > .controls,
  .universal-options-container > .extra {
    text-align: center;
    width: 100%;
    margin: 0.5em 0;
  }

  .universal-metrics-container div.flex {
    flex-direction: column;
  }
  #movable-supercards .demo-containers,
  #fixed-supercards .demo-containers {
    width: 100%;
    margin: 20px 0;
  }
  .demo-containers h1 {
    margin-bottom: 0;
  }
}

/* Just for bigger monitors, it we could use other rule this can change  */
@media (min-width: 1500px) {
  .demo-containers h1 {
    font-size: 3.5rem;
  }

  /* .universal-options-container {
    margin: 1em 4em 0 4em;
  } */
}
