.create-messagegroup-main {
  width: 50%;
  height: 90%;
  margin: auto;
}

.create-messagegroup-card {
  width: 80%;
  margin: 100px 0 auto 0;
  border: 2px solid #ccc;
  box-shadow: 5px 6px 6px #889;
  border-radius: 3px;
}

#create-messagegroup-content {
  width: 100%;
}

#content-input-message {
  display: flex;
  justify-content: space-between;
  height: 50%;
}

#content-input-message .autocomplete-search {
  width: 70%;
  height: 100%;
}
#content-input-message .autocomplete-search-btn {
  width: 20%;
}

.empty-place-holder {
  height: 10px;
}

.label-input {
  display: flex;
  /*justify-content: space-between;*/
  width: 80%;
}
