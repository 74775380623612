/* .card-title-wrapper {
  width: 100%;
  display: flex;
} */

.card-content-wrapper {
  height: 100%;
  width: 100%;
  border-radius: 0 0 6px 6px;
  overflow-y: auto;
  overflow-x: hidden;
}

.card-content-wrapper .ant-spin-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/**
Card Title
 */
/* .card-title-infos {
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 6px 6px 0 0;
}

.card-title-infos-left {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding-left: 10px;
}

.card-title-infos-right {
  height: 100%;
  width: 50%;
  padding-right: 10px;
} */

/**
Card DataContent
 */
.card-list-responsive-container,
.card-list-responsive-container > .ant-spin-nested-loading,
.card-list-responsive-container .ant-spin-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.card-list-responsive-container .ant-row {
  height: min-content;
}

.estimated-metrics-container {
  width: 100%;
  display: flex;
  border-top: 4px solid #000;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

.estimated-metrics-container .ant-statistic-content {
  font-family: Arial, Helvetica, sans-serif;
}

.performance-card-container,
.static-card-container {
  max-height: 200px;
  min-height: 200px;
  border: none;
  margin: 2.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.static-card-marker {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  z-index: 10;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #1890ff;
  top: 0px;
  right: 0px;
}

.static-card-marker-teamRanking {
  border-right: 15px solid #fdb44d;
}
.static-card-container .ant-card-head-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  padding: 0;
  padding-top: 12px;
}

.static-card-container .ant-card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  min-height: 140px;
  font-weight: bold;
}

.static-card-container .ant-card-body::before,
.static-card-container .ant-card-body::after {
  display: none;
}

.static-card-chart-container {
  height: 135px;
  width: 60%;
  max-width: 16vw;
}

.rankings-chart-container {
  height: 135px;
  width: 100%;
}

.rankings-chart-container p {
  margin-top: 50px;
  font-style: italic;
}

.narrow-chart-container {
  height: 158px;
  width: 50%;
  max-width: 15vw;
}

.time-utilization-card-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}

.bar-graph-chart-container {
  height: 150px;
  width: 60%;
  max-width: 15vw;
  position: relative;
}

.time-utilization-card-container .bar-graph-chart-container {
  order: 1;
  height: 120px;
  width: 50%;
  max-width: 15vw;
}

.static-card-secondary-text {
  margin: auto 0;
}

.static-card-container .time-utilization-card-secondary-text {
  order: 2;
  padding-bottom: 0px;
  display: flex;
  width: fit-content;
  flex-direction: column;
  position: relative;
}

.pick-time-container {
  margin-top: 5%;
}

.non-pick-time-container {
  margin-bottom: 5%;
  padding-bottom: 5%;
}

.time-utilization-card-secondary-text-total-hours {
  order: 3;
  color: black;
  font-size: medium;
  display: flex;
  position: absolute;
  top: 92.5%;
  width: 100%;
  height: 25%;
}

.total-working-hours-container {
  border-top: 1px solid black;
  position: absolute;
  top: 70%;
}

.static-card-container h2 {
  margin: auto;
  align-self: flex-end;
  font-size: 40px;
  font-weight: bold;
}

.static-card-container > .ant-card-body > h2 {
  margin: auto 0;
  margin-top: 20px;
}

.static-card-secondary-text h2 {
  margin: auto;
  max-height: 50px;
  width: fit-content;
  font-size: 40px;
  font-weight: bold;
}

.static-card-secondary-text h3 {
  font-size: 18px;
  margin-top: 3px;
}

.time-utilization-card-secondary-text {
  padding-bottom: 10px;
  align-items: end;
}

.time-utilization-card-secondary-text span {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.time-utilization-card-secondary-text h2 {
  font-size: 30px;
  margin-right: 0.3vw;
}

.time-utilization-card-secondary-text h3 {
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  align-self: flex-end;
  margin-bottom: 5px;
}

.static-card-secondary-text a {
  width: 100%;
}

.hover-shade-div {
  padding: 7px;
  padding-top: 0px;
  margin-right: 5px;
  max-width: 7vw;
}

.hover-shade-div:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* this rule refers to the Estimated Hours Saved and Estimated ROI cards in footer of Performance page */
.value-added-card-container {
  max-height: 100%;
  min-height: 100%;
  /*  border: none;
  margin: 2.5px; */
}

.static-card-container:hover {
  cursor: pointer;
}

.performance-card-container:hover,
.static-card-container:hover {
  box-shadow: -1px 1px 10px 3px #e4e4e4;
}
.performance-card-container .ant-card-head,
.value-added-card-container .ant-card-head,
.static-card-container .ant-card-head {
  border-bottom: none;
  min-height: 0px;
}
.performance-card-container .ant-card-body,
.value-added-card-container .ant-card-body {
  padding: 0;
  min-width: 100%;
}

.performance-card-container .ant-card-head-title,
.value-added-card-container .ant-card-head-title {
  padding-bottom: 0;
  padding: 0px;
}

.performance-card-container .ant-statistic-title,
.performance-card-container .ant-statistic-content {
  font-family: Arial, Helvetica, sans-serif;
  display: inline-flex;
  color: rgb(42, 42, 42);
  font-size: 20px;
  margin-right: 4px;
  margin-bottom: 0px;
}

.performance-card-subfields-container .ant-statistic-content-value-int {
  font-size: 20px;
}

.ant-statistic .ant-statistic-content-value-decimal {
  font-size: 55px;
  font-weight: bold;
}

.dynamic-average-container .ant-statistic-content-value-decimal,
.static-goal-container .ant-statistic-content-value-decimal {
  font-size: 20px;
}

.performance-card-subfields-container .ant-statistic-content-value-decimal,
.performance-card-subfields-container .ant-statistic-content-prefix {
  font-size: 20px;
}

.ant-statistic-content-prefix {
  margin-right: 0px;
}

.ant-statistic-content-suffix {
  font-size: 20px;
  margin-left: 0px;
}
.estimated-metrics-container .value-added-card-container .ant-card-head {
  height: 28px;
  min-height: 28px;
}
.performance-select-facility-day,
.performance-select-facility-range {
  min-width: 200px;
  max-height: 32px;
}

/* span#carousel-tooltip {
  position: absolute;
  z-index: 2;
  background-color: #efefef;
  width: 150px;
  right: 50%;
  padding: 3px 5px;
  bottom: -70px;
  display: none;
} */
.performance-select-facility-day:after {
  display: none;
  content: 'Select multiple groups (up to 5) to enable Performance Carousel';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5px 8px;
  z-index: 2;
  top: 40px;
  color: #fff;
  border-radius: 5px;
}
.performance-select-facility-day:hover:after {
  display: block;
}

.performance-select-facility-range:after {
  display: none;
  content: 'Select a single group';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5px 8px;
  z-index: 2;
  top: 40px;
  color: #fff;
  border-radius: 5px;
}
.performance-select-facility-range:hover:after {
  display: block;
}

.performance-range-selector > .ant-select-selection,
.performance-select-facility-range > .ant-select-selection,
.performance-select-facility-day > .ant-select-selection {
  width: 195px;
}

.py-1 > .ant-calendar-picker {
  width: 195px;
}

.performance-select-facility-range > .ant-select-selection,
.performance-select-facility-day > .ant-select-selection {
  max-height: 35px;
}

.performance-range-selector-dropdown > div > ul {
  max-height: none;
}

.performance-range-selector:after {
  display: none;
  content: 'Select a time range';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5px 8px;
  z-index: 2;
  top: 40px;
  color: #fff;
  border-radius: 5px;
}

.performance-range-selector:hover:after {
  display: block;
}

/* .ant-col {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
} */

/* fine-tuning margin for middle column to align with left and right cols */
#performance-settings-container > div:nth-child(2) > h2 {
  margin-bottom: 8.75px;
}

#performance-settings-container > .alt-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

/* .past-averages {
  height: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
} */
.goal-progress-bar.ant-progress .ant-progress-text {
  width: auto;
}
.ant-col.performance-page-col.augmentMetrics {
  width: calc(100% - 5px);
}
div#row-metrics-performance {
  overflow-y: hidden !important;
}
.ant-col.performance-page-col.collapsedAssociates {
  width: 5px;
}
.ant-col.performance-page-col {
  transition: all 0.5s;
}

.ant-row.with-past-averages::before {
  display: table;
  content: none;
}

.ant-row.with-past-averages::after {
  display: table;
  content: none;
}

.with-past-averages {
  display: flex;
  align-items: center;
  width: inherit;
  justify-content: space-evenly;
}

.with-past-averages > div:nth-child(1) {
  max-width: inherit;
}

.with-past-averages > div:nth-child(2) {
  max-width: inherit;
}

.past-averages2-div {
  box-shadow: 2px 2px 10px #ccc;
  padding: 5px;
  height: fit-content;
}

.past-averages2-div p,
.past-averages2-div h3 {
  margin-bottom: 0;
}
.past-averages2-div i {
  font-size: 1rem;
  padding: 5px;
  border-radius: 8px;
}
.past-averages2-div.down-bg i {
  background-color: #ffa4a9;
  color: #f5222d;
}

.past-averages2-div.up-bg i {
  background-color: #a4da8a;
  color: #50ab00;
}

.past-averages2-div.equal-bg i {
  background-color: #fde9a2;
  color: #fdb44d;
}

#seconds-scan .past-averages2-div.up-bg i,
#steps-scan .past-averages2-div.up-bg i,
#cost-pscan .past-averages2-div.up-bg i {
  background-color: #ffa4a9;
  color: #f5222d;
}

#seconds-scan .past-averages2-div.down-bg i,
#steps-scan .past-averages2-div.down-bg i,
#cost-pscan .past-averages2-div.down-bg i {
  background-color: #a4da8a;
  color: #50ab00;
}

.drag-start {
  opacity: 0;
}

.drag-enter {
  opacity: 0.5;
  transform: scale(0.9);
}
.performance-card-container::after,
.time-utilization-card::after {
  content: 'Click for charts, Drag to move';
  font-size: 11px;
  background-color: #f1f1f1;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0 2px;
  opacity: 0.6;
  display: none;
}
.performance-card-container:hover::after,
.time-utilization-card:hover::after {
  display: block;
}

.perf-modal-card {
  height: 200px;
  margin: 2.5px;
  width: 32.9%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
  border: 2px dashed rgba(0, 0, 0, 0.2);
}

.perf-modal-icon {
  font-size: 5em;
  color: rgba(0, 0, 0, 0.2);
}

.perf-modal-icon:hover {
  color: rgba(0, 0, 0, 0.5);
}

.draggable-card {
  position: relative;
}

.performance-card-close-icon {
  position: absolute;
  display: none;
  left: 10px;
  top: 10px;
  color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 1.5em;
  transition: 0.1s;
}

.performance-card-close-icon:hover {
  color: rgba(0, 0, 0, 0.5);
}

.draggable-card:hover .performance-card-close-icon {
  display: block;
}

.draggable-card:hover .perf-modal-card + .performance-card-close-icon {
  display: none;
}

aside#estimated-cards-sider {
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  padding: 0;
  background: #ffffff;
}
aside#estimated-cards-sider.collapse-estimated-cards-sidebar {
  height: 2px;
  max-height: 2px;
  border-top: 4px solid #000;
}
aside#estimated-cards-sider.no-collapse-estimated-cards-sidebar {
  height: 150px;
  max-height: 150px;
}

aside#estimated-cards-sider.collapse-estimated-cards-sidebar
  .estimated-metrics-container {
  display: none;
}
aside#estimated-cards-sider .ant-layout-sider-trigger {
  position: absolute;
  top: -1px;
  background-color: red;
  width: 30px !important;
  border-radius: 50%;
  height: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

aside#estimated-cards-sider .ant-layout-sider-trigger i.anticon {
  font-size: 20px;
  transform: rotate(270deg);
  vertical-align: inherit;
  position: relative;
  top: -5px;
}

.slick-slider.w-100.main-slider-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden !important;
  overflow: initial;
}
div.performance-page-col .slick-list,
div.performance-page-col .slick-track,
div.performance-page-col .slick-slide,
div.performance-page-col .slick-slide > div {
  height: 100% !important;
}

@media (max-width: 768px) {
  .card-title-infos-left,
  .card-title-infos-right {
    width: 100%;
  }
  .card-title-infos-left {
    align-items: center;
    text-align: center;
  }
  .card-title-infos {
    flex-direction: column;
  }
  .performance-select-facility {
    float: none;
    margin-right: 0;
  }
  .past-averages {
    justify-content: center;
  }
}

/** Rule for small screens and below */
@media (max-width: 576px) {
  .estimated-metrics-container {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  aside#estimated-cards-sider.no-collapse-estimated-cards-sidebar {
    height: 180px;
    max-height: 180px;
  }
}

@media (min-width: 2100px) {
  .static-card-chart-container {
    max-width: 15vw;
  }

  .bar-graph-chart-container {
    max-width: 13vw;
  }

  .narrow-chart-container {
    max-width: 10vw;
  }
}

@media (min-width: 2800px) {
  .static-card-chart-container {
    max-width: 13vw;
  }

  .bar-graph-chart-container {
    max-width: 12vw;
  }

  .narrow-chart-container {
    max-width: 8vw;
  }
}
