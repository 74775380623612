.ant-modal-wrap {
  height: 100%;
  width: 100%;
}

.ant-modal-content {
  height: 100%;
}

.chart-page-wrapper {
  width: 100%;
  /* overflow: scroll;
    height: 100%; */
}

.card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-comp-container {
  width: 95%;
  height: 75%;
  border: 1px solid #ccc;
  /* box-shadow: 5px 10px 10px #ccc;*/
  border-radius: 10px;
}

.chart-options-card,
.data-sets-card {
  width: 95%;
  margin: 2rem auto 1rem auto;
  box-shadow: none !important;
}

.card-border {
  border: 1px solid #ccc;
  border-radius: 10px;
}

.chart-options-container {
  display: flex;
  justify-content: space-between;
}

.data-sets-container {
  display: flex;
  align-items: center;
  max-width: 60%;
}

.charts-print-export-container {
  margin-top: 0.5rem;
}

.datasets-and-export-buttons {
  display: flex;
  justify-content: space-between;
  max-width: 80vw;
}

.data-sets-tags-container {
  display: flex;
  flex-flow: row wrap;
  max-width: 80%;
  z-index: 10;
  align-items: start;
  height: 32px;
}

.data-set-tag {
  margin-top: 1rem;
}

.charts-select-team {
  margin-right: 10px;
  max-width: 300px;
  min-width: 150px;
}

.data-type-container,
.chart-date-range-container,
#chart-point-labels-radio,
#chart-omit-null-checkbox,
#chart-show-targets-checkbox {
  display: flex;
  align-items: center;
}

.charts-input-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  max-width: 85vw;
  row-gap: 2vh;
  padding-left: 2vw;
}

.charts-input-row span.ant-radio + * {
  padding-left: 6px;
}

.charts-input-row .ant-radio-wrapper {
  margin: 0;
}

.charts-input-row .ant-checkbox-wrapper {
  margin-left: 8px;
}

.charts-input-row .chart-form-label {
  margin-left: 1vw;
}

.chart-form-label {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

.g2-tooltip {
  background-color: #fff;
  box-shadow: 2px 2px 10px 2px #ccc;
}

ul.g2-tooltip-list {
  list-style: none;
  text-align: left;
  padding: 10px 15px;
  margin: 0;
}
.g2-tooltip p {
  margin-bottom: 0;
  padding-top: 10px;
}
ul.g2-tooltip-list span.g2-tooltip-name {
  padding-right: 1rem;
}

.collapsed-menu-charts {
  position: absolute;
  z-index: 10;
  width: 200px;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: -2px 3px 5px #ccc !important;
}
.customEndPicker .ant-calendar-footer-extra {
  float: left;
}
.customEndPicker .ant-calendar-footer-extra button {
  border: none;
  color: #1890ff;
  background-color: #ffffff;
  cursor: pointer;
  height: 35px;
}
.radios-charts-hrs,
.radios-charts-solos {
  text-align: left;
}
.radios-charts-hrs {
  width: auto;
  margin-right: 10px;
}
.ant-radio-group.ant-radio-group-outline.radios-charts-hrs label,
.radios-charts-solos label {
  display: block;
  margin-right: 0;
}
.customEndPicker a.ant-calendar-ok-btn,
.customStartPicker a.ant-calendar-ok-btn {
  display: none;
}

.over-time {
  line-height: normal;
  /* padding: 3px; */
  height: fit-content;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.over-time > .over-time-value {
  display: inline-block;
}

.over-time > .badge {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
}

.badge::before {
  content: ' ';
}

.over-time i {
  margin-left: 5px;
  padding-right: 2px;
  font-size: 1rem;
  border-radius: 8px;
}

.over-time.negative.down-bg {
  color: #50ab00;
}

.over-time.negative.up-bg {
  color: #f5222d;
}

.over-time.down-bg {
  color: #f5222d;
}

.over-time.up-bg {
  color: #50ab00;
}

.over-time.equal-bg {
  color: #fdb44d;
}
div#popOvertime.ant-popover-inner {
  background-color: rgba(255, 255, 255, 0.4);
  font-weight: bold;
  border: 1px solid;
}

.presets-popover {
  padding: 0;
}

.presets-popover .ant-popover-inner-content {
  padding: 0;
}

.presets-select-item {
  width: 100%;
  padding: 12px;
  /* margin: 0 12px; */
}

.presets-select-delete-btn {
  position: absolute;
  right: 5px;
  z-index: 5px;
}

.presets-arrow {
  float: right;
  font-size: 10px;
  margin-top: 5px;
  transform-origin: 50% 50%;
}

.presets-arrow > svg {
  transition: transform 0.3s;
}

.presets-arrow-open > svg {
  transform: rotate(180deg);
}

.chart-line-container {
  height: 650px;
  min-height: 650px;
  width: 100%;
  margin: auto;
}
.charts-table-container {
  margin-right: auto;
  margin-left: auto;
  margin-top: 4vh;
  width: 85%;
}

.charts-table thead > tr > th {
  background-color: black;
  color: whitesmoke;
  font-weight: 700;
}

.charts-table thead > tr > th:hover {
  cursor: pointer;
}

#chart-only .ant-tabs-tab-prev-icon,
#chart-only .ant-tabs-tab-next-icon {
  opacity: 0;
}

.datepicker-on-charts .ant-calendar-selected-day .ant-calendar-date,
.weekpicker-on-charts
  .ant-calendar-week-number
  .ant-calendar-body
  tr.ant-calendar-active-week {
  /* background: transparent; */
  font-weight: normal;
}
.weekpicker-on-charts
  .ant-calendar-week-number
  .ant-calendar-body
  tr.ant-calendar-current-week {
  /* background: #bae7ff; */
}
.monthpicker-on-charts
  .ant-calendar-month-panel-selected-cell
  .ant-calendar-month-panel-month {
  background: transparent;
  color: #000;
}
.monthpicker-on-charts .ant-calendar-month-panel-month {
  padding: 0;
}
.monthpicker-on-charts
  .ant-calendar-month-panel-cell-disabled
  .ant-calendar-month-panel-month,
.monthpicker-on-charts
  .ant-calendar-month-panel-cell-disabled
  .ant-calendar-month-panel-month:hover {
  background: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
div.hours-picker-wrap div.hours-picker-wrap-fields {
  display: inline;
}
div.hours-picker-wrap div.hours-picker-wrap-fields span.ant-time-picker {
  width: 80px;
}
.ant-select-dropdown.detail-tables-treeselect.ant-select-tree-dropdown.ant-select-dropdown--multiple {
  max-height: 350px !important;
}
div.detail-tables-treeselect span.ant-select-tree-switcher {
  display: none;
}
div.detail-tables-treeselect .ant-select-tree li ul {
  padding: 0;
}
#tables-details-form .ant-card-body {
  display: flex;
  flex-direction: column !important;
  align-items: center;
}
.ant-modal.details-confirmation-modal .ant-modal-content {
  overflow-y: scroll;
  max-height: 80vh;
}
#sum-avg-table-charts .ant-table-column-title:hover {
  cursor: pointer;
}
.charts-table-hover-cell:hover {
  cursor: pointer;
}

.form-input-label {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 3px;
  height: 32px;
}

.rankings-group-select {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.rankings-group-select .ant-select {
  width: 50%;
}

@media (max-width: 1280px) {
  .chart-options-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .data-type-container {
    justify-content: flex-start;
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 1190px) {
  #chart-only .ant-tabs-tab-prev-icon,
  #chart-only .ant-tabs-tab-next-icon {
    opacity: 1;
  }
}
/* @media screen and (max-width: 1650px) {
  .card-comp-container {
    height: 90%;
  }
}
 */
