.whats-new-form .RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-size: 16px;
  padding: 15px;
  line-height: 1.5;
  font-family: "Lato", sans-serif;
  min-height: 300px;
}

.whats-new-form .RichEditor-controls {
  display: inline-block;
  margin-right: 10px;
}

.whats-new-form .RichEditor-controls button {
  user-select: none;
  margin: 0 5px;
  display: inline-block;
}

.whats-new-form .RichEditor-controls button.active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9;
}
.container-whatsnew-form {
  background-color: #ffffff;
  padding: 15px;
}

.whats-new-form .RichEditor-controls .divider {
  background: rgb(235, 236, 240);
  width: 1px;
  height: 24px;
  display: inline-block;
  margin: 0px 8px;
  vertical-align: middle;
}

.whats-new-form .RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
  min-height: inherit;
  max-height: 340px;
  overflow-y: scroll;
}

.whats-new-form .RichEditor-editor .public-DraftEditorPlaceholder-root,
.whats-new-form .RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

/**
  Version notes form
 */
.whats-new-form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0 auto;
}

/**
  Patch notes pages
*/
.whats-new-item {
  background-color: #ffffff;
  padding: 12px 4em;
  flex-direction: column;
  align-items: flex-start;
  margin: 2em;
  box-shadow: 0 24px 48px rgba(13, 21, 55, 0.04);
  border-radius: 5px;
}

div.whats-new-list .ant-list-pagination {
  margin-right: 2em;
}

.whats-new-post-preview {
  max-height: 50px;
}

.whats-new-post-preview h1 {
  font-size: 2em;
  background-color: #ffffff;
  text-align: left;
}

.whats-new-post-container,
#whats-new-editor {
  font-size: 16px;
  line-height: 1.5;
  background-color: #ffffff;
  text-align: left;
}
#whats-new-editor {
  height: 400px;
}

.whats-new-post-container h1,
#whats-new-editor h1 {
  font-size: 1.5em;
}

.whats-new-post-container h2,
#whats-new-editor h2 {
  font-size: 1.3em;
}

.whats-new-post-container *,
#whats-new-editor .RichEditor-editor * {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
