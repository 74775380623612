.conversationheader{
    height: 100%;
    background-color: dodgerblue;
    display: flex;
    align-items: center;
    border-radius: 10px 0px 0px 0px;
}
.conversationheader .ant-page-header-heading-extra {
    position: absolute;
    top: 2px;
    right: 5px;
}