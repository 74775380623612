.side-profile{
    width: 100%;
    background-color: white;
    text-align: center;
    height: 20%;
    color: black;
}

.profile-entry {
    align-items: center;
    width: 100%;
    padding: 10px 10px 10px;

    margin-bottom: 50px;
    word-wrap: break-word
}


/**
For Tablet Size like iPad Pro
*/
@media only screen
and (max-width: 993px) {
    .profile-entry-name {
        font-size: xx-small;
    }
    .profile-entry-position {
        font-size: xx-small;
    }
}