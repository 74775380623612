.end-of-messages {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.message-item {
  width: 100%;
  height: auto;
}

.first-message {
  margin-top: 10px;
}

.message-item-title {
  display: flex;
}

.message-item-content {
  display: flex;
  height: auto;
  width: 100%;
  margin: 2px 0 2px 0;
}

.message-item-text {
  text-align: left;
  line-height: 2;
  word-break: break-word;
  color: black;
  height: 100%;
  width: auto;
  max-width: 60%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 10px 10px 114px 2px rgba(0, 0, 0, 0.26);
}

.message-item-title-name {
  color: black;
  font-weight: bold;
}

.message-item-title-time {
  display: flex;
  font-size: x-small;
  color: grey;
  align-items: center;
}

.loading-dots span {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: rgb(107, 101, 101);
  border-radius: 50%;
  display: inline-block;
  animation-name: dots;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dots {
  50% {
    opacity: 0;
    transform: scale(0.7) translateY(5px);
  }
}
